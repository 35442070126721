import React from "react";
import Footer from "../../components/home/Footer";

const Terms = () => {
 return (
   <div className="w-full flex flex-col pt-[8vh] overflow-hidden">
     <div className="h-[30vh] md:h-[40vh] lg:h-[50vh] w-full bg-terms bg-cover bg-no-repeat bg-center">
       <div className="w-full h-full bg-black/30 backdrop-blur flex px-4 items-center">
         <h1
           data-aos="fade-up"
           className="text-white font-bold text-4xl max-sm:text-2xl mb-4"
         >
           Terms & Conditions
         </h1>
       </div>
     </div>
     <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
       <div className="absolute inset-0 -z-10 overflow-hidden">
         <svg
           className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
           aria-hidden="true"
         >
           <defs>
             <pattern
               id="e813992c-7d03-4cc4-a2bd-151760b470a0"
               width={200}
               height={200}
               x="50%"
               y={-1}
               patternUnits="userSpaceOnUse"
             >
               <path d="M100 200V.5M.5 .5H200" fill="none" />
             </pattern>
           </defs>
           <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
             <path
               d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
               strokeWidth={0}
             />
           </svg>
           <rect
             width="100%"
             height="100%"
             strokeWidth={0}
             fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
           />
         </svg>
       </div>
       <div className="w-full flex flex-col px-4 md:px-6 lg:px-10">
         <p
           data-aos="fade-right"
           className="text-base font-semibold text-green-800 w-full text-left"
         >
           Terms and Conditions
         </p>
         <p className="mt-6 text-lg leading-8 text-gray-500">
           Welcome to Wealth Frontier! These Terms and Conditions govern your
           access to and use of the Wealth Frontier website, including any
           content, features, and services offered on or through the Website. By
           accessing or using the Website, you agree to be bound by these Terms.
           If you do not agree with these Terms, you should not access or use
           the Website.
         </p>
       </div>
       <div className="w-full flex flex-col px-4 md:px-6 lg:px-10 mt-16">
         <p
           data-aos="fade-up"
           className="text-base font-semibold leading-7 text-green-800 w-full text-center"
         >
           Use of the Website
         </p>
         <ul className="w-full list-disc">
           <li className="flex flex-col w-full mt-4">
             <p
               data-aos="fade-right"
               className="text-base font-semibold text-green-800 w-full text-left"
             >
               Eligibility
             </p>
             <p
               data-aos="fade-left"
               className="mt-6 text-lg leading-8 text-gray-500"
             >
               You must be at least 18 years old and have the legal capacity to
               enter into a binding agreement to access and use the Website. By
               accessing and using the Website, you represent and warrant that
               you meet these eligibility requirements.
             </p>
           </li>
           <li className="flex flex-col w-full mt-4">
             <p
               data-aos="fade-right"
               className="text-base font-semibold text-green-800 w-full text-left"
             >
               Account Registration
             </p>
             <p
               data-aos="fade-left"
               className="mt-6 text-lg leading-8 text-gray-500"
             >
               In order to access certain features of the Website, you may be
               required to create an account. You agree to provide accurate,
               complete, and up-to-date information during the registration
               process. You are responsible for maintaining the confidentiality
               of your account information, including your password, and you
               agree to accept responsibility for all activities that occur
               under your account.
             </p>
           </li>
           <li className="flex flex-col w-full mt-4">
             <p
               data-aos="fade-right"
               className="text-base font-semibold text-green-800 w-full text-left"
             >
               Prohibited Activities
             </p>
             <p
               data-aos="fade-left"
               className="mt-6 text-lg leading-8 text-gray-500"
             >
               When using the Website, you agree not to engage in any of the
               following activities
             </p>
             <ul
               data-aos="fade-up"
               className="w-full list-decimal text-gray-500"
             >
               <li className="leading-9">
                 Violating any applicable laws or regulations.
               </li>
               <li className="leading-9">
                 Impersonating any person or entity, or falsely representing
                 your affiliation with any person or entity.
               </li>
               <li className="leading-9">
                 Accessing or using the accounts of other users without
                 authorization.
               </li>
               <li className="leading-9">
                 Transmitting any viruses, worms, malware, or any other harmful
                 code.
               </li>
               <li className="leading-9">
                 Interfering with or disrupting the security, integrity, or
                 performance of the Website.
               </li>
               <li className="leading-9">
                 Collecting or harvesting any personally identifiable
                 information from the Website without permission.
               </li>
               <li className="leading-9">
                 Engaging in any activity that places an excessive load on the
                 infrastructure of the Website.
               </li>
             </ul>
           </li>
         </ul>
       </div>
       <div className="w-full flex flex-col px-4 md:px-6 lg:px-10 mt-16">
         <p
           data-aos="fade-up"
           className="text-base font-semibold leading-7 text-green-800 w-full text-center"
         >
           Investment Services
         </p>
         <ul className="w-full list-disc">
           <li className="flex flex-col w-full mt-4">
             <p
               data-aos="fade-right"
               className="text-base font-semibold text-green-800 w-full text-left"
             >
               General Information
             </p>
             <p
               data-aos="fade-left"
               className="mt-6 text-lg leading-8 text-gray-500"
             >
               Wealth Frontier provides information and services related to
               investment opportunities. The information provided on the Website
               is for informational purposes only and should not be considered
               as financial advice or an endorsement of any investment
               opportunity. You acknowledge that all investment decisions are
               made at your own risk, and you are solely responsible for
               conducting your own research and due diligence before making any
               investment.
             </p>
           </li>
           <li className="flex flex-col w-full mt-4">
             <p
               data-aos="fade-right"
               className="text-base font-semibold text-green-800 w-full text-left"
             >
               No Guarantees
             </p>
             <p
               data-aos="fade-left"
               className="mt-6 text-lg leading-8 text-gray-500"
             >
               Wealth Frontier does not guarantee the accuracy, completeness, or
               timeliness of the information provided on the Website. The
               performance of investments can vary, and past performance is not
               indicative of future results. Wealth Frontier does not guarantee
               any specific investment returns or outcomes.
             </p>
           </li>
           <li className="flex flex-col w-full mt-4">
             <p
               data-aos="fade-right"
               className="text-base font-semibold text-green-800 w-full text-left"
             >
               Third-Party Services
             </p>
             <p
               data-aos="fade-left"
               className="mt-6 text-lg leading-8 text-gray-500"
             >
               The Website may contain links or references to third-party
               websites, products, or services. Wealth Frontier does not endorse
               or assume any responsibility for the content, accuracy, or
               availability of these third-party resources. Your use of
               third-party services is subject to their respective terms and
               conditions and privacy policies.
             </p>
           </li>
         </ul>
       </div>
       <div className="w-full flex flex-col px-4 md:px-6 lg:px-10 mt-16">
         <p
           data-aos="fade-up"
           className="text-base font-semibold leading-7 text-green-800 w-full text-center"
         >
           Intellectual Property
         </p>
         <ul className="w-full list-disc">
           <li className="flex flex-col w-full mt-4">
             <p
               data-aos="fade-right"
               className="text-base font-semibold text-green-800 w-full text-left"
             >
               Ownership
             </p>
             <p
               data-aos="fade-left"
               className="mt-6 text-lg leading-8 text-gray-500"
             >
               The Website and its entire contents, features, and functionality,
               including but not limited to text, graphics, logos, icons,
               images, audio clips, video clips, data compilations, and
               software, are the property of Wealth Frontier or its licensors
               and are protected by intellectual property laws.
             </p>
           </li>
           <li className="flex flex-col w-full mt-4">
             <p
               data-aos="fade-right"
               className="text-base font-semibold text-green-800 w-full text-left"
             >
               Limited License
             </p>
             <p
               data-aos="fade-left"
               className="mt-6 text-lg leading-8 text-gray-500"
             >
               Wealth Frontier grants you a limited, non-exclusive,
               non-transferable, revocable license to access and use the Website
               for personal, non-commercial purposes. You may not modify,
               reproduce, distribute, transmit, display, perform, publish,
               license, create derivative works from, or sell any content or
               information obtained from the Website.
             </p>
           </li>
         </ul>
       </div>
       <div className="w-full flex flex-col px-4 md:px-6 lg:px-10 mt-16">
         <p
           data-aos="fade-up"
           className="text-base font-semibold text-green-800 w-full text-center"
         >
           Privacy
         </p>
         <p
           data-aos="fade-left"
           className="mt-6 text-lg leading-8 text-gray-500"
         >
           Wealth Frontier values your privacy and takes the protection of your
           personal information seriously. Our collection, use, and disclosure
           of your personal information are governed by our Privacy Policy. By
           using the Website, you consent to the collection, use, and disclosure
           of your personal information as described in our Privacy Policy.
         </p>
       </div>
       <div className="w-full flex flex-col px-4 md:px-6 lg:px-10 mt-16">
         <p
           data-aos="fade-up"
           className="text-base font-semibold text-green-800 w-full text-center"
         >
           Limitation of Liability
         </p>
         <p
           data-aos="fade-left"
           className="mt-6 text-lg leading-8 text-gray-500"
         >
           To the fullest extent permitted by applicable law, Wealth Frontier
           and its affiliates, officers, directors, employees, agents, and
           licensors shall not be liable for any direct, indirect, incidental,
           special, consequential, or exemplary damages, including but not
           limited to damages for loss of profits, goodwill, use, data, or other
           intangible losses, resulting from your access to or use of the
           Website or any content or services provided through the Website.
         </p>
       </div>
       <div className="w-full flex flex-col px-4 md:px-6 lg:px-10 mt-16">
         <p
           data-aos="fade-up"
           className="text-base font-semibold text-green-800 w-full text-center"
         >
           Modifications and Termination
         </p>
         <p
           data-aos="fade-left"
           className="mt-6 text-lg leading-8 text-gray-500"
         >
           Wealth Frontier reserves the right to modify, suspend, or terminate
           the Website or any part thereof, at any time, without prior notice.
           We may also revise these Terms from time to time, and such revised
           Terms will be effective immediately upon posting on the Website. Your
           continued use of the Website after any such revisions constitutes
           your acceptance of the revised Terms.
         </p>
       </div>
       <div className="w-full flex flex-col px-4 md:px-6 lg:px-10 mt-16">
         <p
           data-aos="fade-up"
           className="text-base font-semibold text-green-800 w-full text-center"
         >
           Governing Law and Dispute Resolution
         </p>
         <p
           data-aos="fade-left"
           className="mt-6 text-lg leading-8 text-gray-500"
         >
           These Terms shall be governed by and construed in accordance with the
           laws of [Jurisdiction], without regard to its conflict of laws
           principles. Any disputes arising out of or relating to these Terms or
           your use of the Website shall be subject to the exclusive
           jurisdiction of the courts located in [Jurisdiction].
         </p>
       </div>
       <div className="w-full flex flex-col px-4 md:px-6 lg:px-10 mt-16">
         <p
           data-aos="fade-up"
           className="text-base font-semibold text-green-800 w-full text-center"
         >
           Entire Agreement
         </p>
         <p
           data-aos="fade-left"
           className="mt-6 text-lg leading-8 text-gray-500"
         >
           These Terms, together with our Privacy Policy, constitute the entire
           agreement between you and Wealth Frontier regarding your use of the
           Website and supersede any prior or contemporaneous understandings and
           agreements.
         </p>
         <p
           data-aos="fade-left"
           className="mt-6 text-lg leading-8 text-gray-500"
         >
           If you have any questions or concerns about these Terms, please
           contact us at info@wealthfrontier.org.
         </p>
       </div>
     </div>
     <Footer />
   </div>
 );
};

export default Terms;
