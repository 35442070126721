import React, { useEffect, useState } from "react";
import api from "../../apis/api";
import { toast } from "react-toastify";
import Spinner from "../../components/spinners/Spinner";
import ShortenString from "../../components/ShortenString";
import EthModal from "../../components/modals/EthModal";

const AdminEth = () => {
    const [addresses, setAddresses] = useState([]);
    const [loading, setLoading] = useState(false);

    const getAddress = async () => {
      setLoading(true);
      try {
        const res = await api.get("/all-eth-address");
        setAddresses(res.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    const deleteItem = async (e) => {
      e.preventDefault();
      setLoading(true);
      const id = e.target.elements["id"].value;
      try {
        const res = await api.delete(`/eth-delete/${id}`);
        console.log(res.data);
        setLoading(false);
        toast.success(res.data.message);
        getAddress();
      } catch (error) {
        console.log(error);
        setLoading(false);
        toast.error(error.response.data.message);
      }
    };

    useEffect(() => {
      getAddress();
    }, []);

    if (loading === true) {
      return (
        <div className="w-full min-h-screen bg-white pt-[9vh] pl-56 max-lg:pl-0">
          <div className="w-full h-full flex flex-col gap-5 p-5">
            <div className="w-full h-16 flex border-b border-primary items-center">
              <span className="text-2xl font-bold  text-primary">
                Manage Ethereum
              </span>
            </div>
            {/*body*/}
            <div className="w-full flex justify-center my-10">
              <Spinner />
            </div>
          </div>
        </div>
      );
    }
  return (
    <div className="w-full min-h-screen bg-white pt-[9vh] pl-56 max-lg:pl-0">
      <div className="w-full h-full flex flex-col gap-5 p-5">
        <div className="w-full h-16 flex border-b border-primary items-center">
          <span className="text-2xl font-bold  text-primary">
            Manage Ethereum
          </span>
        </div>
        {/*body*/}
        <div className="w-full flex justify-end"><EthModal /></div>
        <div className="w-full flex flex-col gap-1">
          {addresses
            .slice()
            .reverse()
            .map((item) => {
              return (
                <div
                  key={item.id}
                  className="w-full h-8 max-lg:h-10 bg-white px-2 flex items-center text-primary border-b border-primary hover:bg-green-100 transition-all ease-in-out justify-between text-xs max-lg:text-sm"
                >
                  <p className="md:hidden">
                    <ShortenString text={item.address} maxLength={35} />
                  </p>
                  <p className="hidden md:block">{item.address}</p>
                  <form onSubmit={deleteItem}>
                    <input type="hidden" name="id" defaultValue={item.id} />
                    <button className="py-1 px-2">
                      <i class="fa-solid fa-trash"></i>
                    </button>
                  </form>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default AdminEth;
