import React, { useEffect, useRef, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import useFetchMe from "../../hooks/useFetchMe";
import UserDropdown from "../dropdowns/UserDropdown";
import Logo from "../logos/br.png";

const menuItems = [
  { title: "dashboard", link: "/dashboard", icon: "fa-solid fa-house" },
  {
    title: "buy plan",
    link: "/make-deposit",
    icon: "fa-regular fa-credit-card",
  },

  {
    title: "withdrawal",
    link: "/make-withdrawal",
    icon: "fa-solid fa-money-bill-transfer",
  },
  { title: "plans", link: "/plans", icon: "fa-solid fa-chart-simple" },
  {
    title: "transaction history",
    link: "/transaction-history",
    icon: "fa-regular fa-clock",
  },
  {
    title: "earnings history",
    link: "/earnings-history",
    icon: "fa-regular fa-clock",
  },
  { title: "profile", link: "/user-profile", icon: "fa-solid fa-user" },
  {
    title: "manage deposit",
    link: "/restricted-page/admin/deposits",
    icon: "fa-solid fa-money-check",
  },
  {
    title: "manage withdrawal",
    link: "/restricted-page/admin/withdrawals",
    icon: "fa-solid fa-money-check",
  },
  {
    title: "manage plan",
    link: "/restricted-page/admin/plan",
    icon: "fa-solid fa-chart-simple",
  },
  {
    title: "manage bitcoin",
    link: "/restricted-page/admin/btc",
    icon: "fa-brands fa-bitcoin",
  },
  {
    title: "manage usd tether",
    link: "/restricted-page/admin/usdt",
    icon: "fa-solid fa-dollar-sign",
  },
  {
    title: "manage ethereum",
    link: "/restricted-page/admin/eth",
    icon: "fa-brands fa-ethereum",
  },
  {
    title: "manage litecoin",
    link: "/restricted-page/admin/lite",
    icon: "fa-solid fa-litecoin-sign",
  },
  {
    title: "manage users",
    link: "/restricted-page/admin/users",
    icon: "fa-solid fa-users",
  },
  {
    title: "manage verification",
    link: "/restricted-page/admin/users-verification",
    icon: "fa-solid fa-user-shield",
  },
];
const menuItems2 = [
  { title: "dashboard", link: "/dashboard", icon: "fa-solid fa-house" },
  {
    title: "buy plan",
    link: "/make-deposit",
    icon: "fa-regular fa-credit-card",
  },

  {
    title: "withdrawal",
    link: "/make-withdrawal",
    icon: "fa-solid fa-money-bill-transfer",
  },
  { title: "plans", link: "/plans", icon: "fa-solid fa-chart-simple" },
  {
    title: "transaction history",
    link: "/transaction-history",
    icon: "fa-regular fa-clock",
  },
  {
    title: "earnings history",
    link: "/earnings-history",
    icon: "fa-regular fa-clock",
  },
  { title: "profile", link: "/user-profile", icon: "fa-solid fa-user" },
];


const DashboardNavbar = () => {
  const { profile } = useFetchMe();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const navbarRef = useRef(null);
  const menu = profile.role === "admin" ? menuItems : menuItems2;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsOpen(false); // Close the menu if clicked outside the navbar
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav
      className={`h-fit w-full fixed backdrop-blur-sm flex flex-col z-20 ${
        isOpen
          ? "max-lg:bg-white"
          : ""
      }`}
      ref={navbarRef}
    >
      <div className="w-full flex justify-between items-center h-[9vh] px-5 relative">
        <Link to={`/`}>
          <img src={Logo} alt="" className="w-14 h-14" />
        </Link>
        <ul className="gap-5 text-sm flex">
          <UserDropdown />
          <div
            onClick={toggleMenu}
            className={`w-6 h-6 max-lg:flex flex-col justify-between hidden z-50 transition-all ease-in-out items-start `}
          >
            <span
              className={`w-full h-0.5 bg-primary transition-all ease-in-out duration-1000 ${
                isOpen ? "rotate-45 translate-y-2.5" : ""
              }`}
            ></span>
            <span
              className={`w-5 h-0.5 bg-primary transition-all ease-in-out duration-1000 ${
                isOpen ? "opacity-0" : ""
              }`}
            ></span>
            <span
              className={`w-full h-0.5 bg-primary transition-all ease-in-out duration-1000 ${
                isOpen ? "-rotate-45 -translate-y-3" : ""
              }`}
            ></span>
          </div>
        </ul>
        <div
          className={`w-56 h-[91vh] absolute left-0 top-full flex flex-col text-black text-sm font-semibold transition-all ease-in-out  max-lg:bg-white ${
            isOpen ? "max-lg:translate-x-0" : "max-lg:-translate-x-full"
          }`}
        >
          {menu.map((item) => {
            return (
              <Link
                key={item.link}
                to={item.link}
                onClick={() => setIsOpen(false)}
                className={`w-full h-10 lg:h-8 flex justify-between items-center pl-5 lg:text-xs ${
                  location.pathname === item.link
                    ? "text-primary font-bold bg-green-100"
                    : ""
                } transition-all ease-in-out`}
              >
                <div className="flex items-center gap-5">
                  <div className="w-6 flex justify-start">
                    <i className={item.icon}></i>
                  </div>
                  <span className="capitalize">{item.title}</span>
                </div>
                <div
                  className={`w-1.5 h-full ${
                    location.pathname === item.link
                      ? "bg-primary"
                      : "bg-transparent"
                  } transition-all ease-in-out`}
                ></div>
              </Link>
            );
          })}
        </div>
      </div>
    </nav>
  );
};

export default DashboardNavbar;
