import React, { useEffect, useState } from "react";
import CountUp from "react-countup";

const TeslaSector = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || window.pageYOffset;
      const windowHeight = window.innerHeight;
      const elementOffsetTop =
        document.getElementById("count-section").offsetTop;

      if (scrollY + windowHeight >= elementOffsetTop) {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Check if the section is initially visible on page load

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div id="count-section" className="grid w-full grid-cols-3 h-40">
      <div
        data-aos="zoom-in-right"
        data-aos-duration="1500"
        className="w-full h-full flex flex-col items-center justify-center gap-4 p-4"
      >
        <i className="fa-solid fa-route text-4xl text-gray-300"></i>
        <p className="text-xs text-center text-gray-500">
          Navigation to your site from in-car touchscreen
        </p>
      </div>
      <div
        data-aos="zoom-in-down"
        data-aos-duration="1500"
        className="w-full h-full flex flex-col items-center justify-center gap-4 p-4"
      >
        <span className="text-2xl text-gray-300">
          {isVisible && <CountUp end={45000} separator="," duration={2.5} />}+
        </span>
        <p className="text-xs text-center text-gray-500">
          Global Superchargers
        </p>
      </div>
      <div
        data-aos="zoom-in-left"
        data-aos-duration="1500"
        className="w-full h-full flex flex-col items-center justify-center gap-4 p-4"
      >
        <i className="fa-solid fa-users text-4xl text-gray-300"></i>
        <p className="text-xs text-center text-gray-500">
          Attract new & repeat customers to your location
        </p>
      </div>
    </div>
  );
};

export default TeslaSector;
