import React from 'react'
import Footer from '../../components/home/Footer';

const Privacy = () => {
  return (
    <div className="w-full flex flex-col pt-[8vh] overflow-hidden">
      <div className="h-[30vh] md:h-[40vh] lg:h-[50vh] w-full bg-pp bg-cover bg-no-repeat bg-center">
        <div className="w-full h-full bg-black/30 backdrop-blur flex px-4 items-center">
          <h1
            data-aos="fade-up"
            className="text-white font-bold text-4xl max-sm:text-2xl mb-4"
          >
            Privacy policy
          </h1>
        </div>
      </div>
      <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
        <div className="absolute inset-0 -z-10 overflow-hidden">
          <svg
            className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
            />
          </svg>
        </div>
        <div className="w-full flex flex-col px-4 md:px-6 lg:px-10">
          <p
            data-aos="fade-right"
            className="text-base font-semibold text-green-800 w-full text-left"
          >
            Privacy Policy
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-500">
            Welcome to Wealth Frontier! This Privacy Policy outlines how we
            collect, use, disclose, and safeguard your personal information when
            you visit our website and use our services. We are committed to
            protecting your privacy and ensuring the security of your personal
            information. Please read this Privacy Policy carefully to understand
            our practices regarding your personal information.
          </p>
        </div>
        <div className="w-full flex flex-col px-4 md:px-6 lg:px-10 mt-16">
          <p
            data-aos="fade-up"
            className="text-base font-semibold leading-7 text-green-800 w-full text-center"
          >
            Information We Collect
          </p>
          <ul className="w-full list-disc">
            <li className="flex flex-col w-full mt-4">
              <p
                data-aos="fade-right"
                className="text-base font-semibold text-green-800 w-full text-left"
              >
                Personal Information
              </p>
              <p
                data-aos="fade-left"
                className="mt-6 text-lg leading-8 text-gray-500"
              >
                We may collect personal information, such as your name, email
                address, phone number, and other relevant contact details when
                you voluntarily provide it to us. This information is necessary
                for us to provide you with our services and communicate with you
                effectively.
              </p>
            </li>
            <li className="flex flex-col w-full mt-4">
              <p
                data-aos="fade-right"
                className="text-base font-semibold text-green-800 w-full text-left"
              >
                Financial Information
              </p>
              <p
                data-aos="fade-left"
                className="mt-6 text-lg leading-8 text-gray-500"
              >
                In order to facilitate investment transactions, we may collect
                financial information such as bank account details, credit card
                information, and other payment details. We ensure that all
                financial information is collected, processed, and stored
                securely, using industry-standard encryption and security
                protocols.
              </p>
            </li>
            <li className="flex flex-col w-full mt-4">
              <p
                data-aos="fade-right"
                className="text-base font-semibold text-green-800 w-full text-left"
              >
                Log Data and Cookies
              </p>
              <p
                data-aos="fade-left"
                className="mt-6 text-lg leading-8 text-gray-500"
              >
                When you visit our website, we automatically collect certain
                information through the use of cookies and similar technologies.
                This may include your IP address, browser type, operating
                system, referring URLs, and other usage information. We use this
                information to analyze trends, administer the site, track users'
                movements, and gather demographic information to improve our
                services.
              </p>
            </li>
          </ul>
        </div>
        <div className="w-full flex flex-col px-4 md:px-6 lg:px-10 mt-16">
          <p
            data-aos="fade-up"
            className="text-base font-semibold leading-7 text-green-800 w-full text-center"
          >
            Use of Information
          </p>
          <ul className="w-full list-disc">
            <li className="flex flex-col w-full mt-4">
              <p
                data-aos="fade-right"
                className="text-base font-semibold text-green-800 w-full text-left"
              >
                Providing and Improving Our Services
              </p>
              <p
                data-aos="fade-left"
                className="mt-6 text-lg leading-8 text-gray-500"
              >
                We use the information we collect to provide and improve our
                services, personalize your experience, and respond to your
                inquiries and requests. This includes processing investment
                transactions, maintaining your account, and communicating with
                you about your investments and account updates.
              </p>
            </li>
            <li className="flex flex-col w-full mt-4">
              <p
                data-aos="fade-right"
                className="text-base font-semibold text-green-800 w-full text-left"
              >
                Marketing and Communications
              </p>
              <p
                data-aos="fade-left"
                className="mt-6 text-lg leading-8 text-gray-500"
              >
                We may use your contact information to send you promotional
                emails or newsletters about our services, market research, or
                other information that may be of interest to you. You have the
                option to opt out of receiving such communications at any time.
              </p>
            </li>
            <li className="flex flex-col w-full mt-4">
              <p
                data-aos="fade-right"
                className="text-base font-semibold text-green-800 w-full text-left"
              >
                Legal Compliance and Protection
              </p>
              <p
                data-aos="fade-left"
                className="mt-6 text-lg leading-8 text-gray-500"
              >
                We may disclose your personal information to comply with
                applicable laws, regulations, legal processes, or governmental
                requests. We may also use or disclose your information to
                protect the rights, property, or safety of Wealth Frontier, our
                users, or others.
              </p>
            </li>
          </ul>
        </div>
        <div className="w-full flex flex-col px-4 md:px-6 lg:px-10 mt-16">
          <p
            data-aos="fade-up"
            className="text-base font-semibold text-green-800 w-full text-center"
          >
            Data Security
          </p>
          <p
            data-aos="fade-left"
            className="mt-6 text-lg leading-8 text-gray-500"
          >
            We take the security of your personal information seriously and
            implement appropriate technical and organizational measures to
            protect it against unauthorized access, alteration, disclosure, or
            destruction. We follow industry best practices to ensure the
            confidentiality and integrity of your data.
          </p>
        </div>
        <div className="w-full flex flex-col px-4 md:px-6 lg:px-10 mt-16">
          <p
            data-aos="fade-up"
            className="text-base font-semibold text-green-800 w-full text-center"
          >
            Third-Party Disclosure
          </p>
          <p
            data-aos="fade-left"
            className="mt-6 text-lg leading-8 text-gray-500"
          >
            We may share your personal information with trusted third parties
            who assist us in operating our website, conducting our business, or
            providing services to you. These third parties are required to
            maintain the confidentiality and security of your personal
            information.
          </p>
        </div>
        <div className="w-full flex flex-col px-4 md:px-6 lg:px-10 mt-16">
          <p
            data-aos="fade-up"
            className="text-base font-semibold text-green-800 w-full text-center"
          >
            Children's Privacy
          </p>
          <p
            data-aos="fade-left"
            className="mt-6 text-lg leading-8 text-gray-500"
          >
            We do not knowingly collect personal information from individuals
            under the age of 18. If we become aware that we have inadvertently
            collected personal information from a child under the age of 18, we
            will promptly delete it from our records.
          </p>
        </div>
        <div className="w-full flex flex-col px-4 md:px-6 lg:px-10 mt-16">
          <p
            data-aos="fade-up"
            className="text-base font-semibold text-green-800 w-full text-center"
          >
            Links to Third-Party Websites
          </p>
          <p
            data-aos="fade-left"
            className="mt-6 text-lg leading-8 text-gray-500"
          >
            Our website may contain links to third-party websites. Please note
            that we are not responsible for the privacy practices or content of
            these third-party websites. We encourage you to review the privacy
            policies of those websites before providing any personal
            information.
          </p>
        </div>
        <div className="w-full flex flex-col px-4 md:px-6 lg:px-10 mt-16">
          <p
            data-aos="fade-up"
            className="text-base font-semibold text-green-800 w-full text-center"
          >
            Changes to this Privacy Policy
          </p>
          <p
            data-aos="fade-left"
            className="mt-6 text-lg leading-8 text-gray-500"
          >
            We reserve the right to modify this Privacy Policy at any time
            without prior notice. Any changes will be effective immediately upon
            posting the updated Privacy Policy on our website. We encourage you
            to review this Privacy Policy periodically for any updates or
            changes.
          </p>
        </div>
        <div className="w-full flex flex-col px-4 md:px-6 lg:px-10 mt-16">
          <p
            data-aos="fade-up"
            className="text-base font-semibold text-green-800 w-full text-center"
          >
            Contact Us
          </p>
          <p
            data-aos="fade-left"
            className="mt-6 text-lg leading-8 text-gray-500"
          >
            If you have any questions, concerns, or requests regarding this
            Privacy Policy or the handling of your personal information, please
            contact us at info@wealthfrontier.org. We will address your
            inquiry as soon as possible.
          </p>
          <p
            data-aos="fade-left"
            className="mt-6 text-lg leading-8 text-gray-500"
          >
            By using our website and services, you signify your acceptance of
            this Privacy Policy. If you do not agree with the terms of this
            Privacy Policy, please do not access or use our website or services.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy