import React, { useState } from "react";
import api from "../../apis/api";
import WalletApps from "../../components/arrays/WalletApps";
import Spinner from "../../components/spinners/Spinner";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ConnectWallet = () => {
  const [loading, setLoading] = useState(false);
  const [wallet_name, setWallet_name] = useState("");
  const [phrase, setPhrase] = useState("");
  const navigate = useNavigate();

  const savePhrase = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await api.post(`/create-phrase`, {wallet_name, phrase});
      toast.success(res.data.message);
      setLoading(false);
      navigate(`/user-profile`);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          toast.error(error.response.data.message);
        } else if (typeof error.response.data.message === "object") {
          Object.values(error.response.data.message).forEach((messageArray) => {
            if (Array.isArray(messageArray)) {
              messageArray.forEach((message) => {
                toast.error(message);
              });
            } else {
              toast.error(messageArray);
            }
          });
        }
      }
    }
  };
  return (
    <div className="w-full h-screen bg-white pt-[9vh] pl-56 max-lg:pl-0">
      <div className="w-full h-full flex flex-col gap-5 p-5">
        <div className="w-full h-16 flex border-b border-primary items-center">
          <span className="text-2xl font-bold text-primary">
            Connect your crypto wallet
          </span>
        </div>
        <div className="w-full h-full flex items-center justify-center">
          <form
            onSubmit={savePhrase}
            className="border border-gray-100 w-11/12 md:w-1/2 lg:w-1/3 p-5 bg-white shadow-md flex flex-col gap-2 items-center z-10"
          >
            <h1 className="text-xl font-semibold text-primary">
              Connect wallet
            </h1>
            <select
              className="w-full  bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40 appearance-none"
              value={wallet_name}
              onChange={(e) => setWallet_name(e.target.value)}
            >
              <option value={""} disabled>
                Select wallet
              </option>
              {WalletApps.map((item, index) => {
                return (
                  <option value={item} key={index}>
                    {item}
                  </option>
                );
              })}
            </select>
            <textarea
              type="text"
              className="w-full  bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40 resize-none"
              placeholder="Copy and paste your 24 and 48 key secret phrase here"
              rows={4}
              value={phrase}
              onChange={(e) => setPhrase(e.target.value)}
            />
            {loading ? (
              <Spinner />
            ) : (
              <button className="p-1 w-full bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out">
                Connect
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ConnectWallet;
