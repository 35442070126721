import React, { useEffect } from "react";
import AOS from "aos";
import { Link } from "react-router-dom"; 
import Footer from "../../components/home/Footer";
import YouTubeVideo from "../../components/home/YouTubeVideo";

const why = [
  {
    title: "Mobile payments made easy",
    description:
      "Bitcoin when used on a mobile device allows you to pay with a simple two-step scan-and-pay. There's no need to sign up, swipe your card, type a PIN, or sign anything. All you need to receive Bitcoin payments is to display the QR code in your Bitcoin wallet app and let the other party scan your mobile, or touch the two phones together (using NFC radio technology).",
    icon: <i class="fa-solid fa-mobile-button text-green-800"></i>,
  },
  {
    title: "Security and control over your money",
    description:
      "Bitcoin transactions are secured by mathematics and energy. Cryptographic signatures prevent other people from spending your money. Energy spent by proof of work (PoW) prevents other people from undoing, rearranging or losing your transactions. So long as you take the required steps to protect your wallet, Bitcoin can give you control over your money and a strong level of protection against many types of fraud",
    icon: <i class="fa-solid fa-lock text-green-800"></i>,
  },
  {
    title: "Works everywhere, anytime",
    description:
      "Similarly to email, you don't need to ask recipients you're sending bitcoin to, to use the same software, wallets or service providers. You just need their bitcoin address and then you can transact with them anytime. The Bitcoin network is always running and never sleeps, even on weekends and holidays.",
    icon: <i class="fa-solid fa-map-location-dot text-green-800"></i>,
  },
  {
    title: "Protect your identity",
    description:
      "With Bitcoin, there's no credit card number that malicious actors can collect in order to steal from you. In fact, it's even possible in some cases to send a payment without revealing your identity, almost like with physical money. You should, however, take note that some effort can be required to protect your privacy.",
    icon: <i class="fa-solid fa-user-lock text-green-800"></i>,
  },
];
const Bitcoin = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS
    AOS.refresh();
  }, []);
  const youtubeVideoId = "Gc2en3nHxA4";

  return (
    <div className="w-full flex flex-col pt-[8vh] overflow-hidden">
      <div className="h-[30vh] md:h-[40vh] lg:h-[50vh] w-full bg-bitcoin bg-cover bg-no-repeat bg-top">
        <div className="w-full h-full bg-black/30 backdrop-blur flex px-4 items-center">
          <h1
            data-aos="fade-up"
            className="text-white font-bold text-4xl max-sm:text-2xl mb-4"
          >
            Bitcoin
          </h1>
        </div>
      </div>
      <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
        <div className="absolute inset-0 -z-10 overflow-hidden">
          <svg
            className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
            />
          </svg>
        </div>
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="lg:max-w-lg">
                <p
                  data-aos="fade-right"
                  className="text-base font-semibold leading-7 text-green-800"
                >
                  Get started with Bitcoin
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-500">
                  Bitcoin uses peer-to-peer technology to operate with no
                  central authority or banks; managing transactions and the
                  issuing of bitcoins is carried out collectively by the
                  network. Bitcoin is open-source; its design is public, nobody
                  owns or controls Bitcoin and everyone can take part.
                </p>
              </div>
            </div>
          </div>
          <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
            <img
              data-aos="fade-left"
              className="w-[100vh] max-w-none max-sm:w-[60vh]"
              src="https://bitcoin.org/img/home/bitcoin-img.svg?1687792074"
              alt=""
            />
          </div>
          <div className="flex flex-col gap-4 w-full  max-sm:px-2">
            <p
              data-aos="fade-up"
              className="text-base font-semibold leading-7 text-green-800 px-8 w-full text-center"
            >
              Why bitcoin?
            </p>
            <div className="w-full gap-4 grid grid-cols-1">
              {why.map((item) => {
                return (
                  <div
                    data-aos="fade-right"
                    key={item.name}
                    className="relative md:pl-16"
                  >
                    <dt className="text-base font-semibold leading-7 text-green-800">
                      {item.title}
                    </dt>
                    <dd className="mt-2 text-sm leading-5 text-gray-500">
                      {item.description}
                    </dd>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex items-center justify-center py-10">
        <YouTubeVideo videoId={youtubeVideoId} />
      </div>
      <div className="w-full flex flex-col pb-10">
        <p
          data-aos="fade-up"
          className="text-base font-semibold leading-7 text-green-800 px-8 my-4 w-full text-center"
        >
          How does Bitcoin work?
        </p>
        <div className="w-full px-4 md:px-6 lg:px-8 grid grid-cols-1 md:grid-cols-2 gap-4 max-sm:gap-8">
          <div
            data-aos="fade-right"
            className="w-full flex flex-col items-center justify-center lg:px-8"
          >
            <h3 className="text-base font-semibold leading-7 text-green-800 text-center">
              The basics for a new user
            </h3>
            <p className="mt-2 text-sm leading-5 text-gray-500 text-center">
              As a new user, you can get started with Bitcoin without
              understanding the technical details. Once you've installed a
              Bitcoin wallet on your computer or mobile phone, it will generate
              your first Bitcoin address and you can create more whenever you
              need one. You can disclose your addresses to your friends so that
              they can pay you or vice versa. In fact, this is pretty similar to
              how email works, except that Bitcoin addresses should be used only
              once.
            </p>
          </div>
          <div
            data-aos="fade-left"
            className="w-full flex flex-col items-center justify-center p-4"
          >
            <img
              src="https://bitcoin.org/img/icons/new-user.svg?1687792074"
              alt=""
              className="w-full h-full"
            />
          </div>
          <div
            data-aos="fade-right"
            className="w-full flex flex-col items-center justify-center p-4"
          >
            <img
              src="https://bitcoin.org/img/icons/blockchain.svg?1687792074"
              alt=""
              className="w-full h-full"
            />
          </div>
          <div
            data-aos="fade-left"
            className="w-full flex flex-col items-center justify-center lg:px-8"
          >
            <h3 className="text-base font-semibold leading-7 text-green-800 text-center">
              Balances - block chain
            </h3>
            <p className="mt-2 text-sm leading-5 text-gray-500 text-center">
              The block chain is a shared public ledger on which the entire
              Bitcoin network relies. All confirmed transactions are included in
              the block chain. It allows Bitcoin wallets to calculate their
              spendable balance so that new transactions can be verified thereby
              ensuring they're actually owned by the spender. The integrity and
              the chronological order of the block chain are enforced with
              cryptography.
            </p>
          </div>
          <div
            data-aos="fade-right"
            className="w-full flex flex-col items-center justify-center lg:px-8"
          >
            <h3 className="text-base font-semibold leading-7 text-green-800 text-center">
              Transactions - private keys
            </h3>
            <p className="mt-2 text-sm leading-5 text-gray-500 text-center">
              A transaction is a transfer of value between Bitcoin wallets that
              gets included in the block chain. Bitcoin wallets keep a secret
              piece of data called a private key or seed, which is used to sign
              transactions, providing a mathematical proof that they have come
              from the owner of the wallet. The signature also prevents the
              transaction from being altered by anybody once it has been issued.
              All transactions are broadcast to the network and usually begin to
              be confirmed within 10-20 minutes, through a process called
              mining.
            </p>
          </div>
          <div
            data-aos="fade-left"
            className="w-full flex flex-col items-center justify-center p-4"
          >
            <img
              src="https://bitcoin.org/img/icons/private-keys.svg?1687792074"
              alt=""
              className="w-full h-full"
            />
          </div>
          <div
            data-aos="fade-right"
            className="w-full flex flex-col items-center justify-center p-4"
          >
            <img
              src="https://bitcoin.org/img/icons/mining.svg?1687792074"
              alt=""
              className="w-full h-full"
            />
          </div>
          <div
            data-aos="fade-left"
            className="w-full flex flex-col items-center justify-center lg:px-8"
          >
            <h3 className="text-base font-semibold leading-7 text-green-800 text-center">
              Processing - mining
            </h3>
            <p className="mt-2 text-sm leading-5 text-gray-500 text-center">
              Mining is a distributed consensus system that is used to confirm
              pending transactions by including them in the block chain. It
              enforces a chronological order in the block chain, protects the
              neutrality of the network, and allows different computers to agree
              on the state of the system. To be confirmed, transactions must be
              packed in a block that fits very strict cryptographic rules that
              will be verified by the network. These rules prevent previous
              blocks from being modified because doing so would invalidate all
              the subsequent blocks. Mining also creates the equivalent of a
              competitive lottery that prevents any individual from easily
              adding new blocks consecutively to the block chain. In this way,
              no group or individuals can control what is included in the block
              chain or replace parts of the block chain to roll back their own
              spends.
            </p>
          </div>
        </div>
      </div>
      <div className="py-10 w-full flex justify-center">
        <Link
          to="/signup"
          className="py-2 px-10 rounded-sm bg-green-800 text-white shadow-md transition ease-in-out hover:bg-white hover:text-green-800 hover:-translate-y-2 duration-500 text-sm"
        >
          Get started
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default Bitcoin;
