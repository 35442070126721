import React, { useEffect } from "react";
import AOS from "aos";
import { Link } from "react-router-dom";
import Footer from "../../components/home/Footer";
import YouTubeVideo from "../../components/home/YouTubeVideo";

const why = [
  {
    description:
      "Bitcoin when used on a mobile device allows you to pay with a simple two-step scan-and-pay. There's no need to sign up, swipe your card, type a PIN, or sign anything. All you need to receive Bitcoin payments is to display the QR code in your Bitcoin wallet app and let the other party scan your mobile, or touch the two phones together (using NFC radio technology).",
  },
  {
    description:
      "Bitcoin transactions are secured by mathematics and energy. Cryptographic signatures prevent other people from spending your money. Energy spent by proof of work (PoW) prevents other people from undoing, rearranging or losing your transactions. So long as you take the required steps to protect your wallet, Bitcoin can give you control over your money and a strong level of protection against many types of fraud",
  },
  {
    description:
      "Similarly to email, you don't need to ask recipients you're sending bitcoin to, to use the same software, wallets or service providers. You just need their bitcoin address and then you can transact with them anytime. The Bitcoin network is always running and never sleeps, even on weekends and holidays.",
  },
];
const why2 = [
  {
    description:
      "The average monthly transaction volume for one-way machines is $25,000, with the typical commission at around 10% - 15%. For an operator’s first machine, the usual ROI has been between 6-9 months. With our more affordable Gaia model, the time to ROI can be much reduced.",
  },
  {
    description:
      "Once you have the basics down and your business in place, operating a fleet of machines makes much more sense than operating one.",
  },
];
const why3 = [
  {
    title: "Design",
    description:
      "Designed to stand out in a crowd of kiosks, our machines are as high-end as they are visually striking. Skillfully crafted from high grade steel and painstakingly assembled, no detail is overlooked. We use industrial components which have stood the test of time.",
    icon: <i class="fa-solid fa-pen-fancy"></i>,
  },
  {
    title: "0% fees",
    description:
      "You purchase the machine and own it outright, we take no cut of your profits. We charge only a flat fee for our tech .",
    icon: <i class="fa-solid fa-0"></i>,
  },
  {
    title: "Open Source",
    description:
      "Our software is fully open source and decentralised, which means our operators have complete control over their machines and the data and funds that flow through them. Since they don’t depend on a centralised server which could go down, this ensures higher up-time.",
    icon: <i class="fa-solid fa-lock-open"></i>,
  },
  {
    title: "Support",
    description:
      "The tech support we offer is second to none, from techs deeply experienced with the ins and outs of all our machines. They'll have you set up, ready for your first customer in no time, and getting you back on track when you need help.",
    icon: <i class="fa-solid fa-comment-dots"></i>,
  },
];
const Atm = () => {
  useEffect(() => {
    AOS.init(); 
    AOS.refresh();
  }, []);
  const youtubeVideoId = "z-ijExB022M";

  return (
    <div className="w-full flex flex-col pt-[8vh] overflow-hidden">
      <div className="h-[30vh] md:h-[40vh] lg:h-[50vh] w-full bg-atm bg-cover bg-no-repeat bg-center">
        <div className="w-full h-full bg-black/30 backdrop-blur flex px-4 items-center">
          <h1
            data-aos="fade-up"
            className="text-white font-bold text-4xl max-sm:text-2xl mb-4"
          >
            ATM
          </h1>
        </div>
      </div>
      <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
        <div className="absolute inset-0 -z-10 overflow-hidden">
          <svg
            className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
            />
          </svg>
        </div>
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="lg:max-w-lg">
                <p
                  data-aos="fade-right"
                  className="text-base font-semibold leading-7 text-green-800"
                >
                  Starting a Bitcoin ATM Business
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-500">
                  Bitcoin ATMs, aka cryptomats were introduced in early 2013 as
                  a quick and easy solution to the "how do I get Bitcoin?"
                  question. Bitcoin and cryptocurrency have seen a lot of
                  changes over the years, but the Bitcoin ATM industry has
                  remained mostly the same. This is because this solution is not
                  only still relevant, but more so than ever, Bitcoin ATMs are
                  more decentralised than online exchanges and do not have
                  custody of user's funds.
                </p>
              </div>
            </div>
          </div>
          <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
            <img
              data-aos="fade-left"
              className="w-[100vh] max-w-none max-sm:w-[60vh]"
              src="https://lamassu.is/images/isometric-store_v4.svg"
              alt=""
            />
          </div>
          <div className="flex flex-col gap-4 w-full  max-sm:px-2 mb-6">
            <p
              data-aos="fade-up"
              className="text-base font-semibold leading-7 text-green-800 px-8 w-full text-center"
            >
              Why should I start a Bitcoin ATM Business?
            </p>
            <div className="w-full gap-4 grid grid-cols-1">
              {why.map((item) => {
                return (
                  <div
                    data-aos="fade-right"
                    key={item.name}
                    className="relative md:pl-16"
                  >
                    <dd className="mt-2 text-sm leading-5 text-gray-500">
                      {item.description}
                    </dd>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col gap-4 w-full  max-sm:px-2 mb-6">
            <p
              data-aos="fade-up"
              className="text-base font-semibold leading-7 text-green-800 px-8 w-full text-center"
            >
              Is it profitable?
            </p>
            <div className="w-full gap-4 grid grid-cols-1">
              {why2.map((item) => {
                return (
                  <div
                    data-aos="fade-right"
                    key={item.name}
                    className="relative md:pl-16"
                  >
                    <dd className="mt-2 text-sm leading-5 text-gray-500">
                      {item.description}
                    </dd>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex items-center justify-center py-10">
        <YouTubeVideo videoId={youtubeVideoId} />
      </div>
      <div className="w-full py-10 flex-col px-8">
        <p
          data-aos="fade-up"
          className="text-base font-semibold leading-7 text-green-800 px-8 w-full text-center"
        >
          Overhead costs?
        </p>
        <div className="w-full grid grid-cols-5 h-40">
          <div
            data-aos="zoom-in-right"
            data-aos-duration="1500"
            className="w-full h-full flex flex-col items-center justify-center gap-4 p-4"
          >
            <i class="fa-solid fa-bolt text-2xl lg:text-4xl text-gray-300"></i>
            <p className="text-xxs text-center text-gray-500">Power</p>
          </div>
          <div
            data-aos="zoom-in-down"
            data-aos-duration="1500"
            className="w-full h-full flex flex-col items-center justify-center gap-4 p-4"
          >
            <i class="fa-solid fa-wifi text-2xl lg:text-4xl text-gray-300"></i>
            <p className="text-xxs text-center text-gray-500">Internet</p>
          </div>
          <div
            data-aos="zoom-in-up"
            data-aos-duration="1500"
            className="w-full h-full flex flex-col items-center justify-center gap-4 p-4"
          >
            <i class="fa-solid fa-house-circle-exclamation text-2xl lg:text-4xl text-gray-300"></i>
            <p className="text-xxs text-center text-gray-500">Rent</p>
          </div>
          <div
            data-aos="zoom-in-down"
            data-aos-duration="1500"
            className="w-full h-full flex flex-col items-center justify-center gap-4 p-4"
          >
            <i class="fa-solid fa-server text-2xl lg:text-4xl text-gray-300"></i>
            <p className="text-xxs text-center text-gray-500">Server</p>
          </div>
          <div
            data-aos="zoom-in-left"
            data-aos-duration="1500"
            className="w-full h-full flex flex-col items-center justify-center gap-4 p-4"
          >
            <i class="fa-solid fa-comment-dots text-2xl lg:text-4xl text-gray-300"></i>
            <p className="text-xxs text-center text-gray-500">Support</p>
          </div>
        </div>
        <dd className="mt-2 text-sm leading-5 text-gray-500 mb-20">
          It power, an internet connection, any rent for the space in which the
          Bitcoin ATM is hosted, and for a server to host the decentralised
          admin. Our SLA for support is optional, though necessary for ongoing
          support after the first 30 days.
        </dd>
        <p
          data-aos="fade-up"
          className="text-base font-semibold leading-7 text-green-800 px-8 w-full text-center"
        >
          Are our machines Bitcoin-only ATMs or cryptocurrency ATMs?
        </p>
        <dd className="mt-2 text-sm leading-5 text-gray-500">
          Our Bitcoin ATMs support several cryptocurrencies other than Bitcoin.
          You may configure our machines to offer buying and selling of
          Ethereum, Bitcoin Cash, Litecoin, Zcash and Dash.
        </dd>
      </div>
      <div className="flex flex-col gap-4 w-full  mb-6 px-8">
        <p
          data-aos="fade-up"
          className="text-base font-semibold leading-7 text-green-800 px-8 w-full text-center"
        >
          Our competitors have other functionalities, business models and
          prices. So why choose us?
        </p>
        <div className="w-full gap-4 grid grid-cols-1 md:grid-cols-2">
          {why3.map((item) => {
            return (
              <div
                data-aos="fade-right"
                key={item.name}
                className="flex gap-4 items-center"
              >
                <dt className="text-4xl text-green-800">{item.icon}</dt>
                <dd className="mt-2 text-sm leading-5 text-gray-500">
                  {item.description}
                </dd>
              </div>
            );
          })}
        </div>
        <p
          data-aos="fade-up"
          className="text-base font-semibold leading-7 text-green-800 px-8 w-full text-center mt-8"
        >
          How do I get started? What should I do after I order?
        </p>
        <dd className="mt-2 text-sm leading-5 text-gray-500 w-full text-center">
          Drop us a line! We’ll be glad to chat and answer any further questions
          you may have.
        </dd>
      </div>
      <div className="py-10 w-full flex justify-center">
        <Link
          to="/signup"
          className="py-2 px-10 rounded-sm bg-green-800 text-white shadow-md transition ease-in-out hover:bg-white hover:text-green-800 hover:-translate-y-2 duration-500 text-sm"
        >
          Get started
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default Atm;
