import React from "react";
import { Link } from "react-router-dom";
import Logo from "../logos/br.png";

const Footer = () => {
    return (
      <div className="w-full flex flex-col gap-5 p-5 bg-green-100 z-10">
        <div className="w-full grid grid-cols-4 gap-5 max-lg:grid-cols-2">
          <div className="w-full col-span-2 flex flex-col gap-1">
            <div className="w-full flex items-center gap-2">
              <img src={Logo} alt="" className="w-10 h-10" />
              <h3 className="font-semibold text-primary">Wealth Frontier</h3>
            </div>
            <p className="text-xss text-primary lg:w-3/4">
              Wealth Frontier is your gateway to innovative investment
              opportunities in cryptocurrency and beyond. Explore a diverse
              range of investment options, from acquiring Bitcoin through ATMs
              to investing in cutting-edge technologies like Tesla chargers. We
              empower you to navigate the frontier of wealth creation with
              confidence and convenience, providing access to emerging markets
              and revolutionary investment solutions. Join us at Wealth Frontier
              and embark on a journey towards financial growth and technological
              advancement.
            </p>
          </div>
          <div className="w-full flex flex-col gap-2">
            <h3 className="font-semibold text-primary">Useful links</h3>
            <Link
              to={`/`}
              className="w-full flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out"
            >
              <i className="fa-solid fa-caret-right text-primary"></i>
              <span className="text-primary">Home</span>
            </Link>
            <Link
              to={`/about-us`}
              className="w-full flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out"
            >
              <i className="fa-solid fa-caret-right text-primary"></i>
              <span className="text-primary">About Us</span>
            </Link>
            <Link
              to={`/t&c`}
              className="w-full flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out"
            >
              <i className="fa-solid fa-caret-right text-primary"></i>
              <span className="text-primary">Term & Condition</span>
            </Link>
            <Link
              to={`/privacy-policy`}
              className="w-full flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out"
            >
              <i className="fa-solid fa-caret-right text-primary"></i>
              <span className="text-primary">Privacy Policy</span>
            </Link>
          </div>
          <div className="w-full flex flex-col gap-2">
            <h3 className="font-semibold text-primary">Get in touch with us</h3>
            <div className="w-full flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out">
              <i className="fa-solid fa-envelope text-primary"></i>
              <span className="text-primary">info@wealthfrontier.org</span>
            </div>
            <div className="w-full flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out">
              <i className="fa-solid fa-phone text-primary"></i>
              <span className="text-primary">+1 (380)-207-9292</span>
            </div>
            <div className="w-full flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out">
              <i className="fa-solid fa-location text-primary"></i>
              <span className="text-primary">7734 Maud Corners Apt. 934</span>
            </div>
          </div>
        </div>
        <span className="w-full text-center text-primary text-xss">
          © 2023 Wealth Frontier inc. All rights reserved.
        </span>
        <span className="w-full flex justify-center gap-5 text-primary text-xs">
          <i className="fa-brands fa-square-facebook"></i>
          <i className="fa-brands fa-instagram"></i>
          <i className="fa-brands fa-x-twitter"></i>
        </span>
      </div>
    );
};

export default Footer;
