import React, { useEffect, useState } from "react";
import CountUp from "react-countup";

const stats = [
  {
    id: 1,
    name: "Active Users",
    value: 436790,
    icon: "fa-regular fa-user",
  },
  {
    id: 2,
    name: "Supported Countries",
    value: 68,
    icon: "fa-solid fa-earth-americas",
  },
  {
    id: 3,
    name: "Total Earnings",
    value: 860000000,
    icon: "fa-solid fa-wallet",
  },
];

const formatNumber = (value) => {
  if (value >= 1000000) {
    return (value / 1000000).toFixed(1) + "M";
  } else if (value >= 1000) {
    return (value / 1000).toFixed(1) + "K";
  }
  return value;
};

const Section5 = () => {
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const elementPosition =
        document.getElementById("stats-section").offsetTop;

      if (scrollPosition > elementPosition) {
        setInView(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="w-full h-fit flex flex-col justify-center items-center p-5 gap-5 pt-[10vh] bg-transparent">
      <h2
        data-aos="fade-up"
        className="font-semibold text-5xl max-lg:text-3xl text-primary"
      >
        Our Statistics
      </h2>
      <div
        id="stats-section"
        className="flex items-center gap-5 lg:gap-16 max-lg:flex-col mt-10"
      >
        {stats.map((item) => {
          return (
            <div
              data-aos="zoom-in-down"
              key={item.id}
              className="flex flex-col items-center gap-4 z-10  -translate-y-2 ease-in-out transition-all"
            >
              <div
                data-aos="zoom-in-down"
                className="w-20 h-20 rounded-full border border-primary bg-white flex items-center justify-center text-primary text-2xl z-10 animate-pulse"
              >
                <i className={item.icon}></i>
              </div>
              <h5
                data-aos="fade-up"
                className="font-semibold text-lg text-primary"
              >
                {item.name}
              </h5>
              <span className="text-primary">
                {inView ? (
                  <CountUp
                    end={item.value}
                    duration={10}
                    separator=","
                    formattingFn={(value) => formatNumber(value)}
                  />
                ) : (
                  formatNumber(item.value)
                )}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Section5;
