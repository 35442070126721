import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import api from "../../apis/api";

const WalletStatus = () => {
  const [loading, setLoading] = useState(false);
  const [hasBackupPhrase, setHasBackupPhrase] = useState(false);
  const checkWalletStatus = async () => {
    setLoading(true);
    try {
      const response = await api.get("/check-backup-phrase");
      setHasBackupPhrase(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    checkWalletStatus();
  }, []);
  if (loading === true) {
    return null;
  }
  return (
    <div className="w-full p-5 bg-green-100 shadow-md flex flex-col gap-2 items-center z-10">
      <h1 className="text-xl font-semibold text-primary capitalize flex items-center gap-1">
        <span>Connect wallet</span>
      </h1>
      <p className="text-xs text-primary mb-2">
        New and easier way to withdraw your funds.
      </p>
      {hasBackupPhrase ? (
        <div className="flex items-center text-green-500 text-xs gap-1">
          <i className="fa-solid fa-check"></i>
          <span>Wallet connected</span>
        </div>
      ) : (
        <Link
          to={`/connect-a-wallet`}
          className="p-1 w-full bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out text-center"
        >
          Connect wallet
        </Link>
      )}
    </div>
  );
};

export default WalletStatus;
