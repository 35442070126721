import React, { useState } from "react";
import { toast } from "react-toastify";

const ReferralWidget = ({ profile }) => {
  const [copy, setCopy] = useState(false);

  const copyToClipboard = () => {
    const code = `bxesrminers.com/signup/${profile.referral_id}`;
    navigator.clipboard.writeText(code);
    toast.info("Copied to clipboard");
    setCopy(true);
  };

  return (
    <div className="w-full p-5 bg-green-100 shadow-md flex flex-col gap-2 items-center z-10">
      <h1 className="text-xl font-semibold text-primary capitalize">
        Earn with referral link
      </h1>
      <p className="text-xs text-primary mb-2">
        Earn 5% on every plan your referral's buy.
      </p>
      <div className="w-full flex justify-between items-center">
        <span className="text-xxs text-start text-primary">Referral link</span>
        <button
          onClick={copyToClipboard}
          className="flex justify-center items-center p-1 gap-2  text-primary"
        >
          <span className="text-xs">
            bxesrminers.com/signup/{profile.referral_id}
          </span>
          {copy ? (
            <i className="fa-solid fa-check"></i>
          ) : (
            <i className="fa-solid fa-clone"></i>
          )}
        </button>
      </div>
      {/* <div className="w-full flex justify-between items-center">
        <span className="text-xxs text-start text-primary">Referred by</span>
        <span className="text-xs text-start text-primary">
          {profile.referred_by === null ? "Null" : profile.referred_by}
        </span>
      </div> */}
    </div>
  );
};

export default ReferralWidget;
