import React from "react";
import Faq from "../../components/home/Faq";
import Footer from "../../components/home/Footer";
import Section1 from "../../components/home/Section1";
import Section3 from "../../components/home/Section3";
import Section5 from "../../components/home/Section5";
import Section6 from "../../components/home/Section6";
import Section7 from "../../components/home/Section7";
import Sector from "../../components/home/Sector";

const Home = () => {
  return (
    <div className="w-full flex flex-col bg-white overflow-hidden">
      <Section1 />
      <Section3 />
      <Sector />
      <Section5 />
      <Section6 />
      <Faq />
      <Section7 />
      <Footer />
    </div>
  );
};

export default Home;
