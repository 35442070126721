import React from "react";

const items = [
  {
    title: "User-Friendly Interface",
    text: "We understand the importance of a seamless user experience. Our intuitive interface is designed to be accessible to both beginners and advanced traders, ensuring a smooth and efficient trading experience. From account setup to executing trades, Wealth Frontier makes it easy for you to navigate the world of investment.",
    icon: "fa-solid fa-user",
  },
  {
    title: "High Reliability",
    text: "Wealth Frontier is trusted by a huge number of amazing investors. We are working hard constantly to improve the level of security and eliminate every possible risk.",
    icon: "fa-solid fa-lock",
  },
  {
    title: "Diverse Investment Options",
    text: "We offers a wide range of investment options to suit your individual goals and risk appetite. Whether you prefer long-term investments or short-term trading, our platform provides access to Bitcoin and other leading cryptocurrencies, allowing you to diversify your portfolio and explore new opportunities.",
    icon: "fa-solid fa-globe",
  },
  {
    title: "24/7 Support",
    text: "We provide 24 hour support to visitors and investors through the Live Chat althrough the week. Our agents are always on standby ready to assist and attend to your needs. You can locate the Live Chat button at the bottom right corner of the platform.",
    icon: "fa-solid fa-headset",
  },
];

const Section3 = () => {
  return (
    <div className="w-full h-fit flex flex-col justify-center items-center p-5 gap-5 pt-[19vh] bg-transparent">
      <h2
        data-aos="fade-up"
        className="font-semibold text-5xl max-lg:text-3xl text-primary"
      >
        Why Choose Us?
      </h2>
      <div className="w-full grid grid-cols-1 md:grid-cols-2  p-5 gap-5">
        {items.map((item) => {
          return (
            <div
              key={item.title}
              data-aos="fade-right"
              className="w-full p-8 z-10 bg-gray-100 flex flex-col gap-2 group ease-in-out transition-all relative items-center rounded cursor-pointer"
            >
              <div className="rounded-full flex text-white justify-center items-center bg-primary w-12 h-12 absolute -top-3 lg:group-hover:-translate-y-2 transition ease-in-out duration-500">
                <i className={item.icon}></i>
              </div>
              <span className="font-semibold text-center text-primary text-xl mt-5">
                {item.title}
              </span>
              <p className="text-gray-500 font-light text-center">
                {item.text}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Section3;
