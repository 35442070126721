import React, { useState } from "react";

const faq = [
  {
    id: 1,
    title: "What is an Investment Platform?",
    description:
      "An investment platform is an online service which provides access to funds from a wide range of Investment Managers, which means that you can pick various funds from various Investment Managers but you do not have to go to each one of them individually as they are bought by the Platform provider on your behalf. You can hold the various types of product on the Platform such as ISA, Pension, Investment Bonds, Investment Trusts, OEIC’s ETF’s etc.",
  },
  {
    id: 2,
    title: "Who controls the investment Portfolio?",
    description:
      "The investment portfolio is managed by a team of experienced financial specialists, of lawyers, professional trade analysts who have been working on the currency and stock exchange market for more than 10 years on average. Our experience and contacts ensure access for us to a wide range of local and global resources and bring about benefit from the world’s best and most effective technologies of trading on the real estate,oil,Forex, Binary and Cryptocurrency market.",
  },
  {
    id: 3,
    title: "In which countries do we provide consulting services?",
    description:
      "As an international company we accept clients from all over the world and help them build strong financial stability.",
  },
  {
    id: 4,
    title: "How long do I need to tie my money up for?",
    description:
      "There is no need to tie your money up for any specific time period and is down to your own personal requirements although it is recommended that stock market related investments should be envisaged to be kept for 3 to 5 years.",
  },
  {
    id: 5,
    title: "Are my funds secured?",
    description:
      "We’ll take minimum risks while trading to protect our investor’s funds. With our insurance funds, we secure our member’s investments. We are also involved in real estate Investments, and that also guarantees the safety of our investors funds.",
  },
  {
    id: 6,
    title: "What risk do I have to take to generate a good return on my money?",
    description:
      "Everybody has a different concept of what the word risk means so before recommending an investment, we carry out an ‘Attitude to Risk’ analysis that is personal to you so that your investment is matched to your own personal risk rating plus we then offer regular reviews which includes a review of your attitude to risk in order to ensure that your investment remains suitable.",
  },
  {
    id: 7,
    title: "How do I check my account balance?",
    description: "You can see this anytime on your accounts dashboard.",
  },
  {
    id: 8,
    title: "What do I do if I forget my password?",
    description:
      "Click on forgot password in the login page to visit the password reset page, type in your email address and a link would be sent to your email where you can reset your password.",
  },
  {
    id: 9,
    title: "How can I contact Wealth Frontier for support or assistance?",
    description:
      "You can contact our customer support team through the chat button at the bottom right of your page. We're here to assist with any questions or concerns you may have.",
  },
];

const Faq = () => {
  const [openItems, setOpenItems] = useState(new Array(faq.length).fill(false));

  const toggleItem = (index) => {
    const updatedOpenItems = openItems.map((item, i) =>
      i === index ? !item : false
    );
    setOpenItems(updatedOpenItems);
  };

  return (
    <div className="w-full h-fit flex flex-col justify-center items-center p-5 gap-5 pt-[19vh] bg-transparent">
      <h2
        data-aos="fade-up"
        className="font-semibold text-5xl max-lg:text-3xl text-primary"
      >
        FAQ's
      </h2>
      {faq.map((item, index) => (
        <div
          onClick={() => toggleItem(index)}
          key={index}
          className={`p-2 w-full py-2 z-10 cursor-pointer ${
            openItems[index]
              ? "border-primary border-l-4 shadow bg-green-100"
              : "border-transparent"
          } transition-all ease-in-out hover:bg-green-100`}
        >
          <div className="w-full flex items-center justify-between text-primary font-semibold text-lg">
            <h4 className="w-5/6 ">{item.title}</h4>
            {openItems[index] ? "-" : "+"}
          </div>
          <p
            className={`text-gray-500 ${
              openItems[index] ? "h-max" : "h-0 opacity-0"
            } transition-all ease-in-out text-xs`}
          >
            {item.description}
          </p>
        </div>
      ))}
    </div>
  );
};

export default Faq;
