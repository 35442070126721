import React, { useState } from "react";
import useFetchMe from "../../hooks/useFetchMe";
import { toast } from "react-toastify";
import Spinner from "../../components/spinners/Spinner";
import Modal from "../../components/modals/Modal";
import api from "../../apis/api";
import { useNavigate } from "react-router-dom";

const Withdraw = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Bitcoin");
  const { profile, loadUser } = useFetchMe();
  const navigate = useNavigate();
  const payment_method = paymentMethod;
  const user_id = profile.id;
  const type = "withdrawal";
  const transaction = {
    address,
    amount,
    user_id,
    payment_method,
    type,
  };

  const save = () => {
    openModal();
  };

  const makeWithdrawal = (e) => {
    e.preventDefault();
    if (amount === "") {
      toast.warning("Please enter an amount to proceed");
    } else if (amount > parseFloat(profile.wallet)) {
      toast.warning(
        "You do not have up to the amount you are trying to withdraw from your wallet"
      );
    } else {
      if (parseInt(profile.level) === 1 && amount > 2000) {
        toast.warning("Maximum withdrawal for Tier I is $2,000");
      } else if (parseInt(profile.level) === 2 && amount > 10000) {
        toast.warning("Maximum withdrawal for Tier II is $10,000");
      } else {
        save();
      }
    }
  };

  const withdrawFunds = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await api.post("/create-transaction-wit", transaction);
      setLoading(false);
      navigate("/dashboard");
      toast.info(res.data.message);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (loadUser === true) {
    return (
      <div className="w-full min-h-screen bg-white pt-[9vh] pl-56 max-lg:pl-0">
        <div className="w-full h-full flex flex-col gap-5 p-5">
          <div className="w-full h-16 flex border-b border-primary items-center">
            <span className="text-2xl font-bold  text-primary">
              Withdraw Funds
            </span>
          </div>
          {/*body*/}
          <div className="w-full flex justify-center my-10">
            <Spinner />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-screen bg-white pt-[9vh] pl-56 max-lg:pl-0">
      <div className="w-full h-full flex flex-col gap-5 p-5">
        <div className="w-full h-16 flex border-b border-primary items-center">
          <span className="text-2xl font-bold text-primary">
            Withdraw Funds
          </span>
        </div>
        {/*body*/}

        <div className="w-full h-full flex justify-center items-center">
          <form
            onSubmit={makeWithdrawal}
            className="border border-gray-200 w-11/12 md:w-1/2 lg:w-1/3 p-5 bg-white shadow-md flex flex-col gap-2 items-center z-10  max-lg:-translate-y-20 -translate-y-10"
          >
            <h1 className="text-xl font-semibold text-primary">
              Make a withdrawal
            </h1>
            <input
              type="number"
              className="w-full bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40"
              placeholder="Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <select
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
              type="text"
              className="w-full bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40 appearance-none"
            >
              <option value="Bitcoin">Bitcoin</option>
              <option value="USDT">USD Tether</option>
              <option value="Litecoin">Litecoin</option>
              <option value="Ethereum">Ethereum</option>
            </select>
            <button className="p-1 w-full bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out">
              Confirm
            </button>
          </form>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <form
          onSubmit={withdrawFunds}
          className="border border-gray-200 w-full p-5 bg-white shadow-md flex flex-col gap-2 items-center z-10"
        >
          <h1 className="text-xl font-semibold text-primary">Enter address</h1>
          <input
            type="text"
            className="w-full bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40"
            placeholder="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <div className="bg-green-100 text-primary flex gap-2 items-center p-2 border border-primary w-full">
            <i className="fa-solid fa-info text-xs"></i>
            <p className="text-xxs leading-3 text-justify">
              {paymentMethod === "BTC"
                ? "Please provide your bitcoin address which you would use to recieve payment."
                : "Please provide your USDT address which you would use to recieve payment. Please nothe the address provided must my Trc20"}
            </p>
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <button className="p-1 w-full bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out">
              Withdraw
            </button>
          )}
        </form>
      </Modal>
    </div>
  );
};

export default Withdraw;
