import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../apis/api";
import Spinner from "../../components/spinners/Spinner";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");
  const { token } = useParams();
  const navigate = useNavigate();
  const password_reset = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await api.post("/update-by-token", {
        password,
        password_confirmation,
        token,
      });
      setLoading(false);
      toast.success(res.data.message);
      navigate("/login");
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };
  return (
    <div className="w-full h-screen pt-[9vh] flex justify-center items-center bg-white -z-10">
      <form
        onSubmit={password_reset}
        className="border border-gray-100 w-11/12 md:w-1/2 lg:w-1/3 p-5 bg-white shadow-md flex flex-col gap-2 items-center z-10"
      >
        <h1 className="text-xl font-semibold text-primary"> Reset password</h1>
        <input
          type="password"
          className="w-full  bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="password"
          className="w-full  bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40"
          placeholder="Confirm password"
          value={password_confirmation}
          onChange={(e) => setPassword_confirmation(e.target.value)}
        />
        {loading ? (
          <Spinner />
        ) : (
          <button className="p-1 w-full bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out">
            Reset
          </button>
        )}
        <div className="bg-green-100 text-primary flex gap-2 items-center p-2 w-full">
          <div className="rounded-full text-white w-4 h-4 bg-primary flex items-center justify-center">
            <i className="fa-solid fa-info text-xs"></i>
          </div>
          <p className="text-xxs leading-3 text-justify w-full">
            If you forgot your password, well, then we’ll email you instructions
            to reset your password.
          </p>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
