import React from "react";

const ProfileWIdget = ({ profile }) => {
  const dateOfBirth = new Date(profile.age);
  const today = new Date();
  let age = today.getFullYear() - dateOfBirth.getFullYear();
  const monthDiff = today.getMonth() - dateOfBirth.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < dateOfBirth.getDate())
  ) {
    age--;
  }
  return (
    <div className="w-full p-5 bg-green-100 shadow-md flex flex-col gap-2 items-center z-10">
      <h1 className="text-xl font-semibold text-primary">
        Profile Information
      </h1>
      <div className="w-full flex gap-5">
        <span className="w-full text-xxs text-start text-primary">
          First name
        </span>
        <span className="w-full text-xxs text-start text-primary">
          Last name
        </span>
      </div>
      <div className="w-full flex gap-5">
        <div
          type="text"
          className="w-full  bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40 capitalize"
        >
          {profile.firstname}
        </div>
        <div
          type="text"
          className="w-full  bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40 capitalize"
        >
          {profile.lastname}
        </div>
      </div>
      <span className="w-full text-xxs text-start text-primary">Username</span>
      <div
        type="text"
        className="w-full  bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40 capitalize"
      >
        {profile.username}
      </div>
      <div className="w-full flex gap-5">
        <span className="w-full text-xxs text-start text-primary">Age</span>
        <span className="w-full text-xxs text-start text-primary">Gender</span>
      </div>
      <div className="w-full flex gap-5">
        <div
          type="text"
          className="w-full  bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40 capitalize"
        >
          {age}
        </div>
        <div
          type="text"
          className="w-full  bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40 capitalize"
        >
          {profile.gender}
        </div>
      </div>
    </div>
  );
};

export default ProfileWIdget;
