import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../apis/api";
import Spinner from "../spinners/Spinner";

const ChargeFee = ({ id }) => {
  const [loading2, setLoading2] = useState(false);
  const [charge, setCharge] = useState("");
  const chargeUser = async (e) => {
    e.preventDefault();
    setLoading2(true);
    const user_id = id;
    const amount = charge;
    try {
      const res = await api.post("/create-transaction-charge", {
        amount,
        user_id,
      });
      setLoading2(false);
      window.location.reload();
      setCharge("");
      toast.success(res.data.message);
      toast.success("Charge succesful");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setLoading2(false);
    }
  };
  return (
    <form
      onSubmit={chargeUser}
      className="w-full p-5 bg-white border border-gray-100 shadow-lg flex flex-col gap-2 items-center z-10"
    >
      <h1 className="text-xl font-semibold text-primary">Charge User</h1>
      <input
        type="number"
        className="w-full  bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40"
        placeholder="Amount"
        value={charge}
        onChange={(e) => setCharge(e.target.value)}
      />
      {loading2 ? (
        <Spinner />
      ) : (
        <button className="p-1 w-full bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out">
          Charge fee
        </button>
      )}
    </form>
  );
};

export default ChargeFee;
