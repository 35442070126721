import React, { useEffect, useState } from "react";
import api from "../../apis/api";
import Spinner from "../spinners/Spinner";

const Section7 = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);

  const getNews = async () => {
    setLoading(true);
    try {
      const res = await api.get("/crypto-news");
      setNews(res.data.articles.slice(0, 8));
      setLoading(false);
      console.log(res.data.articles.slice(0, 8));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNews();
  }, []);

  const truncateTitle = (title, numWords) => {
    const words = title.split(" ");
    if (words.length > numWords) {
      return words.slice(0, numWords).join(" ") + "...";
    }
    return title;
  };
  return (
    <div className="w-full h-fit flex flex-col justify-center items-center p-5 gap-5 pt-[10vh] bg-transparent relative">
      <h2
        data-aos="fade-up"
        className="font-semibold text-5xl max-lg:text-3xl text-primary"
      >
        Latest News
      </h2>
      {loading ? (
        <div className="my-10">
          <Spinner />
        </div>
      ) : (
        <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
          {news.map((item, index) => {
            return (
              <div
                // data-aos="zoom-in-up"
                className="w-full shadow-md flex flex-col hover:shadow-primary/50 transition-all ease-in-out bg-white z-10 lg:hover:scale-90"
                key={index}
              >
                <img
                  src={item.urlToImage}
                  alt=""
                  className="w-full h-40 object-cover"
                />
                <div className="w-full h-fit flex flex-col">
                  <h5 className="w-full h-16 text-primary text-sm py-1 px-4 cursor-default">
                    {truncateTitle(item.title, 10)}
                  </h5>
                  <div className="w-full flex justify-between items-center p-4 text-xss">
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-400"
                    >
                      Read more
                    </a>
                    <span className="text-primary/80 cursor-default">
                      {item.source.name}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </section>
      )}
    </div>
  );
};

export default Section7;
