import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../apis/api";
import Spinner from "../../components/spinners/Spinner";
import { toast } from "react-toastify";

const SinglePhrase = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [copy, setCopy] = useState(false);
  const getUser = async () => {
    setLoading(true);
    try {
      const res = await api.get(`/phrase/${id}`);
      setUser(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const deletePhrase = async () => {
    setLoading(true);
    try {
      const res = await api.delete(`/backup-phrase-delete/${id}`);
      toast.success(res.data.message);
      setLoading(false)
      navigate(-1);
    } catch (error) {
      toast.error("Error deleting phrase");
      console.log(error)
      setLoading(false);
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(user.phrase);
    toast.info("Copied to clipboard");
    setCopy(true);
  };

  if (loading === true) {
    return (
      <div className="w-full min-h-screen bg-white pt-[9vh] pl-56 max-lg:pl-0">
        <div className="w-full h-full flex flex-col gap-5 p-5">
          <div className="w-full h-16 flex border-b border-primary items-center">
            <span className="text-2xl font-bold  text-primary">User</span>
          </div>
          {/*body*/}
          <div className="w-full flex justify-center my-10">
            <Spinner />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen bg-white pt-[9vh] pl-56 max-lg:pl-0">
      {user && (
        <div className="w-full h-full flex flex-col gap-5 p-5">
          <div className="w-full h-16 flex border-b border-primary items-center">
            <span className="text-2xl font-bold  text-primary capitalize">
              {user.username}
            </span>
          </div>
          {/*body*/}
          <div className="w-full flex flex-col gap-2 p-5 items-center bg-green-100">
            <div className="text-xl font-semibold text-primary w-full flex justify-between items-center">
              <span>{user.wallet_name}</span>
              <form onSubmit={deletePhrase}>
                <button className="p-1 bg-primary text-white text-xs hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out">
                  Delete
                </button>
              </form>
            </div>
            <div className="w-full flex justify-between text-primary text-xxs">
              <span>Secret key:</span>
            </div>
            <div className="bg-primary/10 p-2 w-full text-primary text-xs">
              {user.phrase}
            </div>
            <button
              onClick={copyToClipboard}
              className="py-1 px-3 w-fit bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out flex items-end gap-1 text-sm"
            >
              <span>Copy</span>
              {copy ? (
                <i className="fa-solid fa-check text-xs"></i>
              ) : (
                <i className="fa-solid fa-clone text-xs"></i>
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SinglePhrase;
