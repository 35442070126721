import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../apis/api";
import NewTransactionModal from "../../components/modals/NewTransactionModal";
import Spinner from "../../components/spinners/Spinner";

const UsdtDeposit = () => {
  const number = sessionStorage.getItem("amount");
  const method = sessionStorage.getItem("payment_method");
  const [payment, setPayment] = useState([]);
  const [loading, setLoading] = useState(false);
  const [copy, setCopy] = useState(false);
  const fee = number * 0.05;
  const payThis = parseInt(number) + parseInt(fee);

  const fetchAddress = async () => {
    setLoading(true);
    try {
      const res = await api.get("/last-usdt-address");
      setPayment(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAddress();
    // eslint-disable-next-line
  }, []);

  const copyToClipboard = () => {
    const address = payment.address;
    navigator.clipboard.writeText(address);
    toast.info("Copied to clipboard");
    setCopy(true);
  };

  if (loading === true) {
    return (
      <div className="w-full min-h-screen bg-white pt-[9vh] pl-56 max-lg:pl-0">
        <div className="w-full h-full flex flex-col gap-5 p-5">
          <div className="w-full h-16 flex border-b border-primary items-center">
            <span className="text-2xl font-bold  text-primary">
              Deposit USD Tether
            </span>
          </div>
          {/*body*/}
          <div className="w-full flex justify-center my-10">
            <Spinner />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen bg-white pt-[9vh] pl-56 max-lg:pl-0">
      <div className="w-full h-full flex flex-col gap-5 p-5">
        <div className="w-full h-16 flex border-b border-primary items-center">
          <span className="text-2xl font-bold  text-primary">
            Deposit USD Tether
          </span>
        </div>
        {/*body*/}
        <div className="w-full flex justify-center">
          <div className="w-full md:w-96 lg:w-[50vw] flex max-lg:flex-col gap-2">
            <div className="w-full flex flex-col gap-2">
              <img
                src={payment.qr_code}
                className="w-full h-fit shadow-lg"
                alt="QR Code"
              />
              <button
                onClick={copyToClipboard}
                className="flex justify-center items-center p-1 gap-2 text-primary bg-green-100 shadow"
              >
                <span className="text-xs md:text-xs lg:text-xxs">
                  {payment.address}
                </span>
                {copy ? (
                  <i className="fa-solid fa-check"></i>
                ) : (
                  <i className="fa-solid fa-clone"></i>
                )}
              </button>
            </div>
            <div className="w-full flex flex-col gap-2">
              <div className="w-full flex flex-col text-xs text-black">
                <div className="w-full flex justify-between items-center p-1 border-b border-primary">
                  <span>Amount</span>
                  <span>{number} USDT</span>
                </div>
                <div className="w-full flex justify-between items-center p-1 border-b border-primary">
                  <span>Fee</span>
                  <span>{fee} USDT</span>
                </div>
                <div className="w-full flex justify-between items-center p-1 border-b border-primary">
                  <span>Total</span>
                  <span>{payThis} USDT</span>
                </div>
                <div className="w-full flex justify-between items-center p-1 border-b border-primary">
                  <span>Network</span>
                  <span>Trc20</span>
                </div>
                <div className="w-full flex justify-between items-center px-1 py-1 max-lg:py-2 border-b border-primary">
                  <span>Payment method</span>
                  <span>{method}</span>
                </div>
              </div>
              <div className="bg-green-100 text-primary flex gap-2 items-center p-2 border border-primary w-full">
                <i className="fa-solid fa-info text-lg"></i>
                <p className="text-xxs leading-3 text-justify">
                  Please make a deposit of {payThis} USDT to the address above
                  by either clicking on the address to copy it or scanning on
                  the QR code. Once payment is done click on paid to continue.
                  Note the USDT network to use is{" "}
                  <span className="italic">Trc20</span>
                </p>
              </div>
              <NewTransactionModal />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsdtDeposit;
