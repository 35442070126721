import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../apis/api";
import Country from "../../components/arrays/Country";
import Occupations from "../../components/arrays/Occupations";
import Spinner from "../../components/spinners/Spinner";
import useFetchMe from "../../hooks/useFetchMe";
import { toast } from "react-toastify";

const VerifyID = () => {
  const { profile, loadUser } = useFetchMe();
  const [ssn, setSnn] = useState("");
  const [address, setAddress] = useState("");
  const [dl_front, setDl_front] = useState(null);
  const [dl_back, setDl_back] = useState(null);
  const [phonebody, setPhonebody] = useState("");
  const [countryCode, setCountryCode] = useState("1"); // Default to USA
  const [occupation, setOccupation] = useState("");
  const [salary, setSalary] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const countryCodes = Country;

  const occupationOptions = Occupations;

  const phone = "+" + countryCode + " " + phonebody;

  const salaryRangeOptions = [
    "Less than $50,000",
    "$50,000 - $100,000",
    "$100,000 - $150,000",
    "$150,000 - $200,000",
    "More than $200,000",
  ];

  const findCountryByName = (countryName) => {
    return countryCodes.find((country) => country.name === countryName);
  };

  useEffect(() => {
    const defaultCountry = findCountryByName(profile.country);
    if (defaultCountry) {
      setCountryCode(defaultCountry.phone.toString());
    }
    // eslint-disable-next-line
  }, [profile.country]);

  if (loadUser === true) {
    return (
      <div className="w-full h-screen pt-[9vh] bg-white flex justify-center items-center">
        <Spinner />
      </div>
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("phone", phone);
    formData.append("ssn", ssn);
    formData.append("address", address);
    formData.append("dl_front", dl_front);
    formData.append("dl_back", dl_back);
    formData.append("salary", salary);
    formData.append("occupation", occupation);
    try {
      const res = await api.post("/verify-to-level-3", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);
      navigate(-1);
      toast.info(res.data.message);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="w-full h-screen pt-[9vh] bg-white flex justify-center items-center">
      <form
        onSubmit={handleSubmit}
        className="border border-gray-100 w-11/12 md:w-1/2 lg:w-1/3 p-5 bg-white shadow-md flex flex-col gap-2 items-center z-10"
      >
        <h1 className="text-xl font-semibold text-primary">
          Identity verification
        </h1>
        <input
          type="text"
          className="w-full  bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40"
          placeholder="Enter ID number"
          value={ssn}
          onChange={(e) => setSnn(e.target.value)}
        />
        <textarea
          type="text"
          className="w-full  bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40 resize-none"
          rows={2}
          placeholder="Home address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <div className="flex items-center w-full gap-1">
          <span className="text-primary">{"+" + countryCode}</span>
          <input
            type="text"
            className="w-full  bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40"
            placeholder="Enter mobile number"
            onChange={(e) => setPhonebody(e.target.value)}
          />
        </div>
        <div className="w-full flex gap-2 items-center text-primary text-xs">
          <span>Drivers License (front)</span>
          <input
            type="file"
            className="w-full  bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40 file:bg-primary file:text-white file:border-0"
            onChange={(e) => setDl_front(e.target.files[0])}
          />
        </div>
        <div className="w-full flex gap-2 items-center text-primary text-xs">
          <span>Drivers License (back)</span>
          <input
            type="file"
            className="w-full  bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40 file:bg-primary file:text-white file:border-0"
            onChange={(e) => setDl_back(e.target.files[0])}
          />
        </div>
        <div className="flex gap-2 w-full">
          <select
            className="w-full  bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40 appearance-none"
            value={occupation}
            onChange={(e) => setOccupation(e.target.value)}
          >
            <option value="" disabled>
              Select Occupation
            </option>
            {occupationOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <select
            className="w-full  bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40 appearance-none"
            value={salary}
            onChange={(e) => setSalary(e.target.value)}
          >
            <option value="" disabled>
              Select Salary Range
            </option>
            {salaryRangeOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <button className="p-1 w-full bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out">
            Submit
          </button>
        )}
        <div className="bg-green-100 text-primary flex gap-2 items-center p-2 w-full">
          <div className="rounded-full text-white w-4 h-4 bg-primary flex items-center justify-center">
            <i className="fa-solid fa-info text-xs"></i>
          </div>
          <p className="text-xxs leading-3 text-justify w-full">
            The id field is the your SSN if your country is america, if your
            from Australia then use your Tax File Number, if you are from United
            Kingdom then use your National Insurance number, if you are from
            canada use your SIN/ITN. All other countries must use their
            countries valid identification number.
          </p>
        </div>
      </form>
    </div>
  );
};

export default VerifyID;
