import React from "react";
import Footer from "../../components/home/Footer";

const About = () => {
  return (
    <div className="w-full flex flex-col pt-[8vh] overflow-hidden">
      <div className="h-[30vh] md:h-[40vh] lg:h-[50vh] w-full bg-about bg-cover bg-no-repeat bg-center">
        <div className="w-full h-full bg-black/30 backdrop-blur flex px-4 items-center">
          <h1
            data-aos="fade-up"
            className="text-white font-bold text-4xl max-sm:text-2xl mb-4"
          >
            About us
          </h1>
        </div>
      </div>
      <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
        <div className="absolute inset-0 -z-10 overflow-hidden">
          <svg
            className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
            />
          </svg>
        </div>
        <div className="w-full flex flex-col px-4 md:px-6 lg:px-10 mb-10">
          <p
            data-aos="fade-right"
            className="text-base font-semibold text-green-800 w-full text-left"
          >
            About Wealth Frontier
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-500">
            Welcome to Wealth Frontier, your gateway to financial opportunities
            in the ever-evolving world of investments. We are your trusted
            partner in exploring new horizons, unlocking the potential of your
            wealth, and navigating the diverse landscape of investment options.
            At Wealth Frontier, we empower you to shape your financial future by
            offering a range of cutting-edge services, including Bitcoin, ATM,
            Swap, and Tesla investments.
          </p>
        </div>
        <div className="w-full flex flex-col px-4 md:px-6 lg:px-10 mb-10">
          <p
            data-aos="fade-right"
            className="text-base font-semibold text-green-800 w-full text-left"
          >
            Our Vision
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-500">
            Our vision is to redefine the way individuals think about wealth
            management and investments. We believe that the key to financial
            success lies in embracing innovation, adapting to change, and making
            informed decisions. At Wealth Frontier, we aim to be at the
            forefront of this change, offering you the tools and opportunities
            to thrive in an ever-shifting financial world.
          </p>
        </div>
        <div className="w-full flex flex-col px-4 md:px-6 lg:px-10 mb-10">
          <p
            data-aos="fade-up"
            className="text-base font-semibold text-green-800 w-full text-center mb-2"
          >
            Our Services
          </p>
          <p
            data-aos="fade-right"
            className="text-base font-semibold text-green-800 w-full text-left"
          >
            1. Bitcoin Investment:
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-500 mb-4">
            Investing in cryptocurrencies, particularly Bitcoin, has become a
            global phenomenon. At Wealth Frontier, we provide a secure and
            user-friendly platform to help you tap into the potential of digital
            assets. Whether you're a seasoned crypto enthusiast or new to the
            game, our experts are here to guide you through the intricacies of
            Bitcoin investment.
          </p>
          <p
            data-aos="fade-right"
            className="text-base font-semibold text-green-800 w-full text-left"
          >
            2. ATM Investments:
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-500 mb-4">
            Our ATM investment service offers a unique way to generate passive
            income. By investing in ATMs, you become a part of the
            ever-expanding network of cash distribution, earning a steady return
            on your investment. We ensure that your investment is in safe hands,
            and you benefit from the consistent cash flow generated by these
            machines.
          </p>
          <p
            data-aos="fade-right"
            className="text-base font-semibold text-green-800 w-full text-left"
          >
            3. Swap Investments:
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-500 mb-4">
            Diversify your portfolio and manage your risk with our Swap
            investment service. We offer a range of swap options that allow you
            to exchange one asset for another, all within a secure and regulated
            environment. Our team of experts will help you craft a strategy that
            aligns with your financial goals.
          </p>
          <p
            data-aos="fade-right"
            className="text-base font-semibold text-green-800 w-full text-left"
          >
            4. Tesla Investments:
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-500 mb-4">
            Investing in innovative technologies is a key driver of wealth
            creation in the modern world. With our Tesla investment service, you
            can participate in the future of electric mobility and renewable
            energy. We offer a simple and efficient way to invest in Tesla
            stock, allowing you to be a part of the sustainable energy
            revolution.
          </p>
        </div>
        <div className="w-full flex flex-col px-4 md:px-6 lg:px-10 mb-10">
          <p
            data-aos="fade-right"
            className="text-base font-semibold text-green-800 w-full text-left"
          >
            Join the Wealth Frontier Community
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-500">
            We invite you to join the Wealth Frontier community and embark on a
            journey towards financial empowerment and prosperity. Whether you're
            interested in Bitcoin, ATM investments, swap options, or Tesla, we
            have the tools and expertise to help you achieve your financial
            dreams. <br /> Start your investment journey with Wealth Frontier
            today and experience the future of wealth management. <br />
            Invest in Tomorrow. Today.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
