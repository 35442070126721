import React, { useEffect, useState } from "react";
import api from "../../apis/api";

const ShowActivePlans = () => {
  const [plan, setPlan] = useState([]);

  const getPlan = async () => {
    try {
      const res = await api.get("/show-user-plan");
      setPlan(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPlan();
  }, []);

  const calculateProgress = (duration, initialDuration) => {
    const percentage = (duration / initialDuration) * 100;
    return Math.round(percentage);
  };

  if (plan.length > 0) {
    return (
      <div className="w-full bg-green-100 p-5 flex flex-col gap-2 shadow-lg">
        <span className="w-full flex gap-2">
          <h5 className="text-primary font-semibold text-xs w-full lg:w-1/3">
            Active plans
          </h5>
          <h5 className="text-primary font-semibold text-xs text-center max-lg:text-end w-full lg:w-1/3">
            Amount
          </h5>
          <h5 className="text-primary font-semibold text-xs text-center w-full lg:w-1/3 max-lg:hidden">
            Progress
          </h5>
        </span>
        {plan.map((item) => {
          const progress = calculateProgress(
            item.duration,
            item.initial_duration
          );
          return (
            <div className="w-full grid grid-cols-2 lg:grid-cols-3 gap-2">
              <span className="text-xs text-black w-full">
                {item.reference_number}
              </span>
              <span className="text-xs text-black w-full text-center max-lg:text-end">
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(item.amount)}
              </span>
              <div className="w-full max-lg:col-span-2 text-center">
                <div className="relative w-full h-3 bg-primary/50 rounded-3xl">
                  <div
                    className="absolute h-full bg-primary rounded-3xl"
                    style={{ width: `${progress}%` }}
                  ></div>
                  <div className="absolute top-0 left-0 h-full w-full flex items-center justify-center">
                    <span className="text-xxs text-white">{progress}%</span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  return null;
};

export default ShowActivePlans;
