import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../apis/api";
import Spinner from "../spinners/Spinner";
import Modal from "./Modal";

const EditPasswordModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [old_password, setOld_password] = useState("");
  const [new_password, setNew_password] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");
  const [hidden, sethidden] = useState(true);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const update = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await api.post("/reset-user-password", {
        old_password,
        new_password,
        password_confirmation,
      });
      toast.success(res.data.message);
      setLoading(false);
      closeModal();
      window.location.reload();
    } catch (error) {
      if (error.response.data.message) {
        Object.keys(error.response.data.message).forEach((key) => {
          toast.error(error.response.data.message[key][0]);
        });
      }
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <>
      <button
        onClick={openModal}
        className="px-2 text-xs text-primary ease-in-out transition-all hover:text-primary/50 border-x border-primary"
      >
        Change Password
      </button>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <form
          onSubmit={update}
          className="border border-gray-100 w-full p-5 bg-white shadow-md flex flex-col gap-2 items-center z-10"
        >
          <h1 className="text-xl font-semibold text-primary">
            Update Password
          </h1>
          <input
            type={hidden ? "password" : "text"}
            className="w-full  bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40"
            placeholder="Old password"
            value={old_password}
            onChange={(e) => setOld_password(e.target.value)}
          />
          <div className="w-full flex gap-5">
            <input
              type={hidden ? "password" : "text"}
              className="w-full  bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40"
              placeholder="New password"
              value={new_password}
              onChange={(e) => setNew_password(e.target.value)}
            />
            <input
              type={hidden ? "password" : "text"}
              className="w-full  bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40"
              placeholder="Confirm password"
              value={password_confirmation}
              onChange={(e) => setPassword_confirmation(e.target.value)}
            />
          </div>
          <div className="w-full flex justify-end">
            {hidden ? (
              <div
                onClick={() => sethidden(!hidden)}
                className="flex items-center gap-1 text-primary text-xs cursor-pointer"
              >
                <p className="">Show password</p>
                <i class="fa-regular fa-eye"></i>
              </div>
            ) : (
              <div
                onClick={() => sethidden(!hidden)}
                className="flex items-center gap-1 text-primary text-xs cursor-pointer"
              >
                <p className="">Hide password</p>
                <i class="fa-regular fa-eye-slash"></i>
              </div>
            )}
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <button className="p-1 w-full bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out">
              Update
            </button>
          )}
        </form>
      </Modal>
    </>
  );
};

export default EditPasswordModal;
