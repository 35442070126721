import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../apis/api";
import useFetchMe from "../../hooks/useFetchMe";
import Spinner from "../spinners/Spinner";
import Modal from "./Modal";

const NewTransactionModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transaction_id, setTransaction_id] = useState("");
  const amount = sessionStorage.getItem("amount");
  // const amount = parseInt(number) + parseInt(number * 0.05);
  const payment_method = sessionStorage.getItem("payment_method");
  const plan_id = sessionStorage.getItem("plan_id");
  const { profile, loadUser } = useFetchMe();
  const user_id = profile.id;
  const type = "deposit";
  const transaction = { amount, payment_method, user_id, type, plan_id, transaction_id };
  const navigate = useNavigate();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const create = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await api.post("/create-transaction-dep", transaction);
      sessionStorage.removeItem("amount");
      sessionStorage.removeItem("payment_method");
      closeModal();
      toast.info("Deposit processing");
      console.log(res);
      navigate("/dashboard");
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);

      setLoading(false);
    }
  };
  return (
    <>
      <button
        onClick={openModal}
        className="bg-primary px-4 py-1.5 text-sm text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out"
      >
        Paid
      </button>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div className="border border-primary w-full p-5 bg-white shadow-md flex flex-col gap-2 items-center z-10">
          <h1 className="text-xl font-semibold text-primary">
            Have you made payment?
          </h1>
          {loadUser ? (
            <div className="w-full flex justify-center py-3">
              <Spinner />
            </div>
          ) : (
            <form onSubmit={create} className="w-full flex flex-col gap-5">
              <input
                type="text"
                className="w-full bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40"
                placeholder="Enter the Transaction ID"
                value={transaction_id}
                onChange={(e) => setTransaction_id(e.target.value)}
              />
              <div className="w-full flex justify-center">
                {loading ? (
                  <div className="w-full flex justify-center py-3">
                    <Spinner />
                  </div>
                ) : (
                  <div className="w-full gap-5 flex">
                    <button
                      onClick={closeModal}
                      className="bg-primary w-full py-1.5 text-sm text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out"
                    >
                      No
                    </button>
                    <button className="bg-primary w-full px-4 py-1.5 text-sm text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out">
                      Paid
                    </button>
                  </div>
                )}
              </div>
            </form>
          )}
        </div>
      </Modal>
    </>
  );
};

export default NewTransactionModal;
