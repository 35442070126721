import React from "react";
import coin from "../backgrounds/ss.png";

const Section1 = () => {
  return (
    <div className="w-full h-screen bg-green-100 rounded-3xl">
      <div className="w-full h-full flex max-lg:flex-col justify-center items-center p-5 pt-[10vh] gap-10">
        <div className="flex flex-col w-full lg:w-1/2 items-center">
          <h1 className="text-2xl lg:text-3xl text-primary font-medium w-full text-left">
            Welcome to <span className="text-primary">Wealth Frontier</span>
          </h1>
          <span className="italic text-gray-400 text-xs w-full text-left">
            Empowering Tomorrow's Wealth Today
          </span>
          <p className="text-gray-600 text-sm max-lg:text-xs mt-4">
            Wealth Frontier is your gateway to innovative investment
            opportunities in cryptocurrency and beyond. Explore a diverse range
            of investment options, from acquiring Bitcoin through ATMs to
            investing in cutting-edge technologies like Tesla chargers.
          </p>
          <button className="py-1 px-5 w-fit bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out mt-4 text-sm">
            Get started
          </button>
        </div>
        <div className="flex w-full lg:w-1/2 items-center justify-center">
          <img
            data-aos="fade-left"
            src={coin}
            alt=""
            className="h-[35vh] lg:h-[70vh]"
          />
        </div>
      </div>
    </div>
  );
};

export default Section1;
