import React, { useEffect, useRef, useState } from "react";
import useFetchMe from "../../hooks/useFetchMe";
import { Link, useNavigate } from "react-router-dom";
import api from "../../apis/api";
import { toast } from "react-toastify";

const UserDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const { profile, loadUser } = useFetchMe();
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const logOut = async (e) => {
    e.preventDefault()
    try {
      const res = await api.post("/logout");
      toast.info(res.data.message);
      localStorage.removeItem("access_token");
      navigate("/");
    } catch (error) {
      toast.error(error.response.data.message);
      if (error.response.data.message === "Unauthenticated.") {
        localStorage.removeItem("access_token");
        navigate("/login");
      }
    }
  };

  return (
    <div ref={buttonRef} className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="py-1 px-2 rounded bg-transparent border text-primary border-primary text-xs max-lg:text-sm flex gap-1 items-center"
      >
        <i className="fa-solid fa-user"></i>
        <span className="capitalize">
          {loadUser ? "Username" : profile.username}
        </span>
      </button>

      <div
        className={`absolute right-0 w-36 ${
          isOpen ? "h-24 border" : "h-0"
        } bg-white mt-2 flex flex-col transition-all ease-in-out text-primary shadow`}
      >
        <Link
          to="/dashboard"
          onClick={() => setIsOpen(false)}
          className={`w-full h-8 px-2 items-center gap-2 text-sm hover:bg-green-100 transition-all ease-in-out ${
            isOpen ? "flex" : "hidden"
          }`}
        >
          <span>Dashboard</span>
        </Link>
        <Link
          to="/user-profile"
          onClick={() => setIsOpen(false)}
          className={`w-full h-8 px-2 items-center gap-2 text-sm hover:bg-green-100 transition-all ease-in-out ${
            isOpen ? "flex" : "hidden"
          }`}
        >
          <span>Profile</span>
        </Link>
        <button onClick={logOut}
          className={`w-full h-8 px-2 items-center gap-2 text-sm hover:bg-green-100 transition-all ease-in-out ${
            isOpen ? "flex" : "hidden"
          }`}
        >
          <span>Logout</span>
        </button>
      </div>
    </div>
  );
};

export default UserDropdown;
