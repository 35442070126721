import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import api from "../../apis/api";
import { Link } from "react-router-dom";
import Spinner from "../../components/spinners/Spinner";

const IpPage = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const usersWithTwoIps = async () => {
    setLoading(true);
    try {
      const res = await api.get("/users/shared-ip");
      setUsers(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    usersWithTwoIps();
  }, []);

  if (loading === true) {
    return (
      <div className="w-full min-h-screen bg-white pt-[9vh] pl-56 max-lg:pl-0">
        <div className="w-full h-full flex flex-col gap-5 p-5">
          <div className="w-full h-16 flex border-b border-primary items-center">
            <span className="text-2xl font-bold  text-primary">
              Check Multiple IP's
            </span>
          </div>
          {/*body*/}
          <div className="w-full flex justify-center my-10">
            <Spinner />
          </div>
        </div>
      </div>
    );
  }

  if (users.length === 0) {
    return (
      <div className="w-full min-h-screen bg-white pt-[9vh] pl-56 max-lg:pl-0">
        <div className="w-full h-full flex flex-col gap-5 p-5">
          <div className="w-full h-16 flex border-b border-primary items-center">
            <span className="text-2xl font-bold  text-primary">
              Check Multiple IP's
            </span>
          </div>
          {/*body*/}
          <div className="w-full flex justify-center my-10 text-primary text-xs">
            No users have Multiple IP's.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen bg-white pt-[9vh] pl-56 max-lg:pl-0">
      <div className="w-full h-full flex flex-col gap-5 p-5">
        <div className="w-full h-16 flex border-b border-primary items-center">
          <span className="text-2xl font-bold  text-primary">
            Check Multiple IP's
          </span>
        </div>
        {/*body*/}
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search by username or ip address"
          className="w-full  bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40"
        />
        <div className="w-full flex flex-col">
          {users
            .filter((user) => {
              return (
                user.username
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase()) ||
                user.ip_address.toLowerCase().includes(searchTerm.toLowerCase())
              );
            })
            .map((item) => {
              return (
                <div className="w-full p-1 max-lg:p-2 h-12 border-b border-primary bg-white hover:bg-green-100 transition-all ease-in-out grid grid-cols-2 text-primary">
                  <Link
                    to={`/restricted-page/admin/users/${item.id}`}
                    className="w-full h-full flex flex-col px-2 justify-center items-start"
                  >
                    <span className="text-sm font-medium capitalize">
                      {item.username}
                    </span>
                    <span className="text-xs font-extralight">
                      {item.email}
                    </span>
                  </Link>
                  <Link
                    to={`/restricted/admin/users/${item.id}`}
                    className="w-full h-full flex px-2 justify-end items-center"
                  >
                    <span className="text-sm font-medium">
                      {item.ip_address}
                    </span>
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default IpPage;
