import React from "react";

const ContactWidget = ({ profile }) => {
  return (
    <div className="w-full p-5 bg-green-100 shadow-md flex flex-col gap-2 items-center z-10">
      <h1 className="text-xl font-semibold text-primary">
        Contact Information
      </h1>
      <div className="w-full flex gap-5">
        <span className="w-full text-xxs text-start text-primary">
          Email address
        </span>
        <span className="w-full text-xxs text-start text-primary">
          Phone number
        </span>
      </div>
      <div className="w-full flex gap-5">
        <div
          type="text"
          className="w-full  bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40"
        >
          {profile.email}
        </div>
        <div
          type="text"
          className="w-full  bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40 capitalize"
        >
          {profile.phone === null ? "Nil" : profile.phone}
        </div>
      </div>
      <span className="w-full text-xxs text-start text-primary">
        Country of residence
      </span>
      <div
        type="text"
        className="w-full  bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40 capitalize"
      >
        {profile.country}
      </div>
      <span className="w-full text-xxs text-start text-primary">Address</span>
      <div
        type="text"
        className="w-full  bg-gray-100 text-xs p-2 outline-none border text-primary border-gray-200 focus:border-primary placeholder:text-primary/40 capitalize"
      >
        {profile.address === null ? "Nil" : profile.address}
      </div>
    </div>
  );
};

export default ContactWidget;
