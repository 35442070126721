import React, { useEffect } from "react";
import AOS from "aos";
import { Link } from "react-router-dom";
import YouTubeVideo from "../../components/home/YouTubeVideo";
import TeslaSector from "../../components/home/TeslaSector";
import Footer from "../../components/home/Footer";


const why = [
  {
    title: "Network Expansion",
    description:
      "Tesla has ambitious plans for further expanding its Supercharger network. Continued investments in infrastructure development will not only support the company's own EV sales but also enable partnerships with other automakers, potentially generating additional revenue streams.",
  },
  {
    title: "Scalability and Monopoly",
    description:
      "Tesla's Supercharger network, due to its extensive coverage and proprietary technology, holds a dominant position in the EV charging space. As the EV market grows, this monopoly-like advantage could lead to significant long-term profitability and potential partnerships or acquisitions.",
  },
  {
    title: "Evolving Regulations",
    description:
      "With increasing government support for the EV industry, regulations and incentives favoring the expansion of charging infrastructure are expected to emerge. This could further bolster Tesla's Supercharger network and provide a favorable investment climate.",
  },
];
const Tesla = () => {
    useEffect(() => {
      AOS.init(); // Initialize AOS
      AOS.refresh();
    }, []);
  const youtubeVideoId = "TszRyT8hjJE";

  return (
    <div className="w-full flex flex-col pt-[8vh] overflow-hidden">
      <div className="h-[30vh] md:h-[40vh] lg:h-[50vh] w-full bg-tesla bg-cover bg-no-repeat bg-center">
        <div className="w-full h-full bg-black/30 backdrop-blur flex items-center">
          <h1
            data-aos="fade-up"
            className="text-white font-bold text-4xl max-sm:text-2xl mb-4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 278.7 36.3"
              width="256"
              height="256"
              className="scale-50"
            >
              <path
                fill="#ffffff"
                d="M238.1 14.4v21.9h7V21.7h25.6v14.6h7V14.4h-39.6M244.3 7.3h27c3.8-.7 6.5-4.1 7.3-7.3H237c.8 3.2 3.6 6.5 7.3 7.3M216.8 36.3c3.5-1.5 5.4-4.1 6.2-7.1h-31.5V.1h-7.1v36.2h32.4M131.9 7.2h25c3.8-1.1 6.9-4 7.7-7.1H125v21.4h32.4V29H132c-4 1.1-7.4 3.8-9.1 7.3h41.5V14.4H132l-.1-7.2M70.3 7.3h27c3.8-.7 6.6-4.1 7.3-7.3H62.9c.8 3.2 3.6 6.5 7.4 7.3M70.3 21.6h27c3.8-.7 6.6-4.1 7.3-7.3H62.9c.8 3.2 3.6 6.5 7.4 7.3M70.3 36.3h27c3.8-.7 6.6-4.1 7.3-7.3H62.9c.8 3.2 3.6 6.6 7.4 7.3M0 .1c.8 3.2 3.6 6.4 7.3 7.2h11.4l.6.2v28.7h7.1V7.5l.6-.2h11.4c3.8-1 6.5-4 7.3-7.2V0L0 .1"
                class="color5e5e5e svgShape"
              ></path>
            </svg>
          </h1>
        </div>
      </div>
      <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
        <div className="absolute inset-0 -z-10 overflow-hidden">
          <svg
            className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
            />
          </svg>
        </div>
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="lg:max-w-lg">
                <p
                  data-aos="fade-right"
                  className="text-base font-semibold leading-7 text-green-800"
                >
                  Investing in Tesla's Supercharger Network
                </p>
                <p
                  data-aos="fade-up"
                  className="mt-6 text-lg leading-8 text-gray-500 mb-6"
                >
                  Investing in Tesla, the renowned electric vehicle (EV)
                  manufacturer, offers numerous opportunities for investors
                  seeking to capitalize on the rapidly growing EV market. One
                  particular aspect of Tesla's business that stands out is its
                  Supercharger network—a global network of high-speed charging
                  stations designed to provide convenient and efficient charging
                  solutions for Tesla owners. This short note aims to explore
                  the investment potential of Tesla's Supercharger network and
                  the impact it has on the EV industry.
                </p>
                <p
                  data-aos="fade-right"
                  className="text-base font-semibold leading-7 text-green-800"
                >
                  The Growing Importance of Electric Vehicle Charging
                  Infrastructure:
                </p>
                <p
                  data-aos="fade-up"
                  className="mt-6 text-lg leading-8 text-gray-500"
                >
                  As the adoption of electric vehicles continues to surge
                  worldwide, the demand for a reliable and extensive charging
                  infrastructure becomes increasingly crucial. Tesla recognized
                  this need early on and established its Supercharger network to
                  alleviate range anxiety and accelerate EV adoption. Today, the
                  Supercharger network has become one of Tesla's key
                  differentiating factors, enhancing the appeal of their
                  vehicles and fueling their competitive advantage.
                </p>
              </div>
            </div>
          </div>
          <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
            <img
              className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
              src="https://cdn.pixabay.com/photo/2016/10/13/22/30/tesla-1738969_1280.jpg"
              alt=""
            />
          </div>
          <div className="flex flex-col gap-4 w-full  max-sm:px-2">
            <p
              data-aos="fade-up"
              className="text-base font-semibold leading-7 text-green-800 px-8 w-full text-center"
            >
              Why invest in tesla?
            </p>
            <div className="w-full gap-4 grid grid-cols-1">
              {why.map((item) => {
                return (
                  <div
                    data-aos="fade-right"
                    key={item.name}
                    className="relative md:pl-16"
                  >
                    <dt className="text-base font-semibold leading-7 text-green-800">
                      {item.title}
                    </dt>
                    <dd className="mt-2 text-sm leading-5 text-gray-500">
                      {item.description}
                    </dd>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex items-center justify-center py-10">
        <YouTubeVideo videoId={youtubeVideoId} />
      </div>
      <TeslaSector />
      <div className="w-full flex flex-col pb-10">
        <p
          data-aos="fade-up"
          className="text-base font-semibold leading-7 text-green-800 px-8 my-4 w-full text-center"
        >
          Host a SuperCharger?
        </p>
        <div className="w-full px-4 md:px-6 lg:px-8 grid grid-cols-1 md:grid-cols-2 gap-4 max-sm:gap-8">
          <div
            data-aos="fade-right"
            className="w-full flex flex-col items-center justify-center lg:px-8"
          >
            <h3 className="w-full text-base font-semibold leading-7 text-gray-300 text-left">
              Benefits
            </h3>
            <h1 className="w-full text-2xl font-semibold leading-7 text-green-800 text-left">
              Attract Customers
            </h1>
            <p className="mt-2 text-sm leading-5 text-gray-500 text-left">
              Tesla vehicles are equipped with in-car navigation that directs
              drivers to nearby Superchargers, bringing new and repeat customers
              to your location.
            </p>
          </div>
          <div
            data-aos="fade-left"
            className="w-full flex flex-col items-center justify-center p-4"
          >
            <img
              src="https://digitalassets.tesla.com/tesla-contents/image/upload/h_1800,w_2880,c_fit,f_auto,q_auto:best/SH_D_1"
              alt=""
              className="w-full h-full"
            />
          </div>
          <div
            data-aos="fade-right"
            className="w-full flex flex-col items-center justify-center p-4"
          >
            <img
              src="https://digitalassets.tesla.com/tesla-contents/image/upload/h_1800,w_2880,c_fit,f_auto,q_auto:best/Installation-Hero-Desktop"
              alt=""
              className="w-full h-full"
            />
          </div>
          <div
            data-aos="fade-left"
            className="w-full flex flex-col items-center justify-center lg:px-8"
          >
            <h3 className="w-full text-base font-semibold leading-7 text-gray-300 text-left">
              Installation
            </h3>
            <h1 className="w-full text-2xl font-semibold leading-7 text-green-800 text-left">
              Fast and Flexible
            </h1>
            <p className="mt-2 text-sm leading-5 text-gray-500 text-left">
              Tesla manages the construction and operation of Superchargers at
              your site—no extra upkeep or maintenance required. Our team of
              Tesla installers will get your Superchargers up and charging
              quickly with a fast, efficient installation process.
            </p>
          </div>
          <div
            data-aos="fade-right"
            className="w-full flex flex-col items-center justify-center lg:px-8"
          >
            <h3 className="w-full text-base font-semibold leading-7 text-gray-300 text-left">
              Location
            </h3>
            <h1 className="w-full text-2xl font-semibold leading-7 text-green-800 text-left">
              Growing Network
            </h1>
            <p className="mt-2 text-sm leading-5 text-gray-500 text-left">
              Join the largest fast charging network in the world. Ideal hosting
              sites can support eight or more individual parking stalls and
              provide a hospitable charging experience with amenities such as
              restaurants, groceries, shops, restrooms and Wi-Fi. Ongoing site
              selection is based on market expansion needs, as well as popular
              routes and destinations.
            </p>
          </div>
          <div
            data-aos="fade-left"
            className="w-full flex flex-col items-center justify-center p-4"
          >
            <img
              src="https://digitalassets.tesla.com/tesla-contents/image/upload/h_1800,w_2880,c_fit,f_auto,q_auto:best/Location-Hero-Desktop"
              alt=""
              className="w-full h-full"
            />
          </div>
        </div>
      </div>
      <div className="py-10 w-full flex justify-center">
        <Link
          to="/signup"
          className="py-2 px-10 rounded-sm bg-green-800 text-white shadow-md transition ease-in-out hover:bg-white hover:text-green-800 hover:-translate-y-2 duration-500 text-sm"
        >
          Get started
        </Link>
      </div>
      <Footer/>
    </div>
  );
};

export default Tesla;
